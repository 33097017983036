import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import saleIcon from '../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../components/common/IconButton'
import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker'
import { boxStyle } from '../../constants/types'
import SynchronizationsService from '../../services/synchronizations'
import { brandModalText } from '../menu/brands/brand-modal'
import BulkSync from './bulk-sync'

const ConfirmSyncModal = ({
	open,
	type,
	onClose,
	value,
	modalTitle,
	modalSubTitle,
}) => {
	const [loading, setLoading] = useState(false)
	const [progress, setProgress] = useState(0)
	const [date, setDate] = useState(value.toISOString().split('T')[0])
	const [method, setMethod] = useState('automatic_api')
	const [productType, setProductType] = useState()

	const onChange = (e) => {
		setMethod(e.target.value)
	}

	useEffect(() => {
		let timer
		if (loading) {
			timer = setInterval(() => {
				setProgress((prevProgress) => {
					if (prevProgress >= 90) {
						return 10
					}
					return prevProgress + 0.44
				})
			}, 1000)
		}
		return () => {
			clearInterval(timer)
		}
	}, [loading])

	useEffect(() => {
		if (progress === 100) {
			setLoading(false)
		}
	}, [progress])

	const onCancel = () => {
		onClose()
		setMethod('')
		setProgress(0)
		setLoading(false)
	}

	const handleSubmit = () => {
		SynchronizationsService.SyncRequest(type, {
			sync_date: date,
			...(productType ? { type: productType.value } : {}),
		})
			.then((response) => {
				setLoading(false)
				setProgress(100)

				if (response.status === 'success') {
					// Success response handling
					NotificationManager.success(response.message, 'Success', 3000);
				} else {
					// Handle unexpected response
					NotificationManager.error('An unexpected error occurred', 'Error', 3000);
				}
			})
			.catch((error) => {
				setLoading(false)
				setProgress(100)
				if (error.response) {
					const { status, data } = error.response;

					if (status === 400) {
						// Validation error response
						const { message, errors } = data;
						NotificationManager.error(`${message}: ${JSON.stringify(errors)}`, 'Validation Error', 3000);
					} else if (status === 500) {
						// Alpha system error response
						NotificationManager.error(`${data.message}: ${data.error}`, 'Server Error', 3000);
					} else {
						// Generic error handling for other statuses
						NotificationManager.error(data.message || 'An error occurred', 'Error', 3000);
					}
				} else {
					// Network or other unexpected errors
					NotificationManager.error('Synchronization failed: ' + (error.message || 'Unknown error'), 'Error', 3000);
				}

			})
		if (method.length > 0) {
			setLoading(true)

			if (progress === 100) {
				onCancel()
			}
		}
	}

	const handleOnDateChange = (date) => {
		setDate(date)
	}

	const options = [
		{
			label: 'Single Products',
			value: 'single',
		},
		{
			label: 'Variant Products',
			value: 'variants',
		},
	]

	const disableSubmitBtn = type === 'calculate-stock' ? !productType : false

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="category-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none px-024 pb-024">
				<div className="mb-6">
					<CustomIconButton px={false} icon={saleIcon} title={modalTitle} />
					<span className="text-gray5 text-md font-medium">
						{modalSubTitle}
					</span>
				</div>

				<div className="flex flex-col gap-2 pt-0 mb-4 items-center">
					<CustomDateTimePicker
						placeholder={''}
						classes="housekeeping_task_due_date w-full"
						value={date}
						onChange={handleOnDateChange}
						onlyDate={true}
					/>

					{/* <CustomMUISelect
						data={methodOptions}
						onChange={onChange}
						value={method}
						placeholder="Select method"
						className="!w-full"
						width="w-full"
					/> */}

					{type === 'calculate-stock' && (
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select Product Type"
							value={productType}
							onChange={(_, option) => {
								setProductType(option)
							}}
							options={options}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							suffixIcon={<ArrowDropDownIcon />}
						/>
					)}

					{method === 'manual_csv' && <BulkSync />}
				</div>

				{loading && (
					<div className="w-full my-4 flex items-center">
						<Typography
							variant="body2"
							color="textSecondary"
							align="left"
							style={{ marginRight: '16px' }}
						>
							{progress === 100 ? 'Success!' : 'Synching...'}
						</Typography>
						<LinearProgress
							variant="determinate"
							value={progress}
							sx={{
								height: 10,
								flexGrow: 1,
								backgroundColor: 'lightgray',
								'& .MuiLinearProgress-bar': {
									backgroundColor: 'green',
								},
							}}
						/>
						<Typography
							variant="body2"
							color="textSecondary"
							align="right"
							style={{ marginLeft: '16px' }}
						>
							{progress}%
						</Typography>
					</div>
				)}

				{!loading && (
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCancel}
						>
							{brandModalText('cancel')}
						</button>
						<button
							className={`rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white ${disableSubmitBtn ? 'cursor-not-allowed opacity-50' : ''
								}`}
							onClick={handleSubmit}
							disabled={disableSubmitBtn}
						>
							{progress === 100 && (
								<CheckCircleIcon style={{ color: 'green', marginRight: 8 }} />
							)}
							{progress === 100
								? brandModalText('close')
								: brandModalText('sync')}
						</button>
					</div>
				)}
			</Box>
		</Modal>
	)
}

export default ConfirmSyncModal
