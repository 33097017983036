
export const columns = [
   {
      label: 'Title',
      field: 'title',
      align: 'left',
      // cellComponent: ({ data }) => {
      //    return (
      //       <span className="whitespace-nowrap">{moment(data?.created_at).format("YYYY-MM-DD")}</span>)
      // },
   },
   {
      label: 'Blog Title',
      field: 'blog_title',
      align: 'left',
   },
   {
      label: 'Number of Competitions',
      field: 'nr_competitions',
      align: 'left',
      // cellComponent: ({ data }) => {
      //    return <span>${data}</span>
      // }
   },
   {
      label: 'Registered Users',
      field: 'registered_users',
      align: 'left',
      // cellComponent: ({ data }) => {
      //    return <span>${data}</span>
      // }
   },
   // {
   //    label: 'Status',
   //    field: 'status',
   //    align: 'center',
   //    cellComponent: ({ data }) => {
   //       let badgeBgColor = "";
   //       let badgeText = "";

   //       switch (data) {
   //          case "active":
   //             badgeBgColor = "bg-green-100 text-green-800";
   //             badgeText = "Active";
   //             break;
   //          case "inactive":
   //             badgeBgColor = "bg-red-100 text-red-800";
   //             badgeText = "Inactive";
   //             break;
   //       }

   //       return (
   //          <span className={`${badgeBgColor} text-sm font-medium px-2.5 py-0.5 rounded`}>
   //             {badgeText}
   //          </span>
   //       );
   //    },
   // },
   // { label: 'Actions', field: 'actions' },
   // { label: "Total Questions", field: "total_questions", align: "left" },

   {
      label: "Correct Answers",
      field: "answers_stats.correct",
      align: "left",
   },
   {
      label: "Wrong Answers",
      field: "answers_stats.wrong",
      align: "left",
   },


   { label: "Total Points Earned", field: "total_points_earned", align: "left" },
]