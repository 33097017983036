import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useDebounce from '../../../components/Hooks/useDebounce'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'
import DeviceService from '../../../services/device-service'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { umbrellasText } from '../../beachbar/umbrellas'
import AddEditDeviceModal from './add-edit-device'
import { deviceColumns } from './data'
import DeviceFilters from './device-filters'

export const devicesViewText = (text) => t(`devices.devices.${text}`)

export default function DevicesScreen() {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)
	const [selectedFilter, setSelectedFilter] = useState({
		label: 'Filter by Brand',
		value: 'filter_by_brand',
	})

	const [selectedDevice, setSelectedDevice] = useState()

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(true)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [paginatedData, setPaginatedData] = useState([])
	const [open, setOpen] = useState(false)
	const [search, setSearch] = useState('')
	const [openDelete, setOpenDelete] = useState(false)

	const debouncedSearch = useDebounce(search, 400)

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage)
		setPage(0)
	}

	const onEdit = (row) => {
		setSelectedDevice(row)
		setOpen(true)
	}

	const onDelete = (row) => {
		setOpenDelete(true)
		setOpen(false)
		setSelectedDevice(row)
	}

	const onStream = (row) => {
		setSelectedDevice(row)
		navigate(`/${short_code}/admin/vision-track/devices/${row.id}/stream`)
	}

	const getDevices = () => {
		let query = `&page=${page}&per_page=${rowsPerPage}`

		if (debouncedSearch.length > 0) {
			query += `&search=${debouncedSearch}`
		}
		if (selectedFilter.value !== 'filter_by_brand') {
			query += `&brand=${selectedFilter.value}`
		}

		DeviceService.GetAll(query)
			.then((res) => {
				setLoaded(true)
				setData(res?.data ?? [])
				setPaginatedData(res?.data?.slice(0, rowsPerPage))
				identifyVenue(short_code)
				trackEvent('device service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'all devices',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('device service', {
					action_category: 'get',
					action_outcome: 'error',
					interaction_element: 'all devices',
				})
			})
	}

	useEffect(() => {
		getDevices()
	}, [rowsPerPage, debouncedSearch, selectedFilter])

	const deleteDevice = () => {
		DeviceService.deleteDevice(selectedDevice)
			.then(() => {
				getDevices()
				setSelectedDevice(undefined)
				identifyVenue(short_code)
				trackEvent('device service', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'delete a device',
				})
				NotificationManager.success(
					'Device has been deleted successfully',
					'Success',
					3000
				)
			})
			.catch((err) => {
				setSelectedDevice(undefined)
				identifyVenue(short_code)
				trackEvent('device service', {
					action_category: 'delete',
					action_outcome: 'error',
					interaction_element: 'a single device',
				})
				NotificationManager.error(
					err?.errors?.email?.[0] ??
						'An error occurred while deleting the device.',
					'Error',
					3000
				)
			})
	}

	return (
		<div className="w-full">
			<div className="text-black1 text-xl font-bold flex-1 pb-6">
				{devicesViewText('devices')}
			</div>
			{loaded ? (
				<div className="flex flex-col h-full">
					<Card className="!rounded-[12px]" elevation={0}>
						<CardContent className="!px-[25px] !py-[25px] !m-0 flex flex-col gap-[25px] !pb-0">
							<DeviceFilters
								{...{
									setOpen,
									search,
									setSearch,
									selectedFilter,
									setSelectedFilter,
									setSelectedDevice,
								}}
							/>
							{paginatedData.length && paginatedData.length > 0 ? (
								<>
									<GenericTable
										data={paginatedData}
										columns={deviceColumns}
										onEdit={onEdit}
										onStream={onStream}
										onDelete={onDelete}
										className="!px-[0px] !py-[0px] overflow-x-auto"
									/>
									<GenericPagination
										onPageChange={handleChangePage}
										page={page}
										count={data.length}
										onRowsPerPageChange={handleChangeRowsPerPage}
										rowsPerPage={rowsPerPage}
										setPage={setPage}
									/>
								</>
							) : (
								<div className="mt-5 mb-10">
									<TableEmptyData
										content={devicesViewText('devicesLowerCase')}
										btnLabel={devicesViewText('add_device')}
										// secondBtn={exportIcon}
										onAction={() => setOpen(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>

					<AddEditDeviceModal
						open={open}
						onClose={() => setOpen(false)}
						getDevices={getDevices}
						selectedDevice={selectedDevice}
					/>

					<AreYouSureModal
						title={devicesViewText('delete_device')}
						content={devicesViewText('delete_device_confirmation')}
						firstButton={umbrellasText('no_go_back')}
						secondButton={umbrellasText('yes_i_am')}
						showModal={openDelete}
						onClose={() => setOpenDelete(false)}
						onSubmit={deleteDevice}
					/>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
