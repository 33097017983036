import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { ReactComponent as CloseIcon } from '../../../../assets/svgs/close.svg'
import IconButton from '../../../../components/common/IconButton'
import { RInputDeclare } from '../../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../../constants/types'
import useIsMobile from '../../../../helpers/is-mobile-hook'
import DetectionActivitiesService from '../../../../services/detectionActivities'
import { activitiesText } from './index'

export const parseLabel = (label) =>
	label.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())

export default function AssignActivityModal({
	onClose,
	open,
	initialValues,
	selectedDevice,
	GetDeviceActivities,
}) {
	const isMobile = useIsMobile()
	const initialValue = useMemo(() => {
		return {
			device_id: selectedDevice.value,
			venue_detection_activity_id: '',
		}
	}, [])

	const [config, setConfig] = useState(initialValue)
	const [selectedAvtivity, setSelectedActivity] = useState()

	const [loading, setLoading] = useState(false)

	const [venueActivities, setVenueActivities] = useState([])

	useEffect(() => {
		if (initialValues) {
			setConfig({
				...initialValues.config,
				venue_detection_activity_id: initialValues.venue_detection_activity_id,
				device_id: initialValues.device_id,
			})
			setSelectedActivity(
				venueActivities.find(
					(activity) =>
						activity.value === initialValues.venue_detection_activity_id
				)
			)
		}
	}, [initialValues, venueActivities])

	const fetchOptions = () => {
		DetectionActivitiesService.GetVenueActivities('').then((res) => {
			const newOptions = res.map((item) => ({
				label: item.activity.name,
				value: item.id,
				originalData: item,
			}))
			setVenueActivities((prev) => [...prev, ...newOptions])
		})
	}

	useEffect(() => {
		fetchOptions()
	}, [])

	const onSubmit = () => {
		setLoading(true)
		const { venue_detection_activity_id, device_id, ...rest } = config
		const payload = { venue_detection_activity_id, device_id, config: rest }

		DetectionActivitiesService.AssignActivityToDevice(payload)
			.then(() => {
				setLoading(false)
				NotificationManager.success(
					'Activity has been assigned successfully',
					'Success',
					3000
				)
				GetDeviceActivities()
				onClose()
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(
					err?.errors?.email?.[0] ??
						'An error occurred while assigning the activity.',
					'Error',
					3000
				)
			})
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...boxStyle,
					maxWidth: 600,
					width: '100%',
					height: isMobile ? '100%' : 'unset',
					borderRadius: isMobile ? 0 : '16px',
				}}
				className="outline-none px-024 pb-024 overflow-auto sm:max-h-[90vh]"
			>
				<div className="flex justify-between items-center">
					<IconButton
						px={false}
						// icon={activitysIcon}
						title={
							initialValues
								? activitiesText('edit_modal_title')
								: activitiesText('assign_modal_title')
						}
					/>
					<div className="pt-024 cursor-pointer" onClick={onClose}>
						<CloseIcon />
					</div>
				</div>
				<div className="flex flex-col gap-4 py-0">
					<div className="flex flex-col py-0">
						<RInputDeclare
							value={'Device'}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select"
							value={selectedDevice}
							suffixIcon={<ArrowDropDownIcon />}
							options={[selectedDevice]}
							disabled={true}
						/>
					</div>
					<div className="flex flex-col py-0">
						<RInputDeclare
							value={activitiesText('venue_activity')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select Activity"
							value={config?.venue_detection_activity_id}
							onChange={(value, option) => {
								setConfig({
									...config,
									...(option?.originalData?.config ?? {}),
									venue_detection_activity_id: value,
								})
								setSelectedActivity(option)
							}}
							options={venueActivities}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							suffixIcon={<ArrowDropDownIcon />}
						/>
					</div>

					{selectedAvtivity?.originalData?.config &&
						Object.entries(selectedAvtivity?.originalData?.config).map(
							([key]) => (
								<div key={key} className="flex flex-col py-0">
									<label className="font-medium text-base text-black1 mb-2">
										{parseLabel(key)}
									</label>
									<TextField
										required
										size="small"
										type="number"
										value={config?.[key]}
										placeholder={parseLabel(key)}
										onChange={(e) =>
											setConfig({ ...config, [key]: e.target.value })
										}
										margin="none"
										fullWidth
									/>
								</div>
							)
						)}
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{activitiesText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							<>
								{initialValues
									? activitiesText('update')
									: activitiesText('assign_activity')}
							</>
						)}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
