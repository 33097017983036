import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import locationIcon from '../../../assets/svgs/location.svg'
import GenericModal from '../../../components/generic-modal'
import { boxStyle } from '../../../constants/types'
import MarketingService from '../../../services/marketingService'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import { KEYS } from '../../../utils/storage'
import PromotionForm from './promotion-form'

export const promotionsModalText = (text) =>
	t(`marketing.promotions.PromotionModal.${text}`)

export default function PromotionModal({
	showModal,
	data,
	coupons = [],
	discounts = [],
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const [state, setState] = useState({
		available: 1,
	})

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				if (data.discounts.length > 0) {
					const { discounts, ...rest } = data
					setState({ ...rest, discount_id: `ID-D#${data.discounts[0]?.id}` })
				}

				if (data.coupons.length > 0) {
					const { coupons, ...rest } = data
					setState({ ...rest, coupon_id: `ID-C#${data.coupons[0]?.id}` })
				}
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		const { type, title, description } = state

		if (type && title && description) {
			if (state.id) {
				MarketingService.UpdatePromotion({
					...state,
					discount_id: state.discount_id
						? parseInt(state.discount_id.replace('ID-D#', ''))
						: null,
					coupon_id: state.coupon_id
						? parseInt(state.coupon_id.replace('ID-C#', ''))
						: null,
				})
					.then((res) => {
						onClose(true)
					})
					.catch((res) => {
						NotificationManager.error('Failed', '')
					})
			} else {
				MarketingService.CreatePromotion({
					...state,
					discount_id: state.discount_id
						? parseInt(state.discount_id.replace('ID-D#', ''))
						: null,
					coupon_id: state.coupon_id
						? parseInt(state.coupon_id.replace('ID-C#', ''))
						: null,
				})
					.then((res) => {
						onClose(true)
					})
					.catch(() => {
						NotificationManager.error('Failed', '')
						throw new Error('Problem on creating a Promotion')
					})
			}
		}
	}

	const handleChangeType = (value, index) => {
		setState((v) => ({ ...v, type: value.target.value }))
	}

	const handleChangeDiscount = (value) => {
		setState((v) => ({ ...v, discount_id: value.target.value }))
	}

	const handleChangeCoupon = (value) => {
		setState((v) => ({ ...v, coupon_id: value.target.value }))
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'promotion_description'
		if (aiField === 'Title') {
			askFor = 'promotion_title'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || t('common.error_generic')
					)
				} else {
					NotificationManager.error(t('common.error_generic'))
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)

		if (aiField === 'Title') {
			setState({ ...state, title: generated })
		}

		if (aiField === 'Description') {
			setState({ ...state, description: generated })
		}

		setAiQuery('')
		setGenerated('')
	}

	return (
		<div>
			<GenericModal
				{...{ onClose, onSubmit, loading }}
				open={visible}
				saveText={
					state.id
						? promotionsModalText('update')
						: promotionsModalText('create')
				}
				icon={locationIcon}
				children={
					<PromotionForm
						{...{
							data,
							handleChangeCoupon,
							handleChangeDiscount,
							handleChangeType,
							handleOpenAIModal,
							setState,
							state,
							venueType,
							coupons,
							discounts,
						}}
					/>
				}
				title={promotionsModalText(
					data?.id ? 'edit_promotion' : 'new_promotion'
				)}
			/>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{promotionsModalText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{promotionsModalText('details')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-tranparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												promotionsModalText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{promotionsModalText('add_to')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
