import { API_ANALYTICS } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class AnalyticsService {
   static GetAnalyticsRecent = async (query) => {
      let api = {
         content_type: 'application/json',
         url: `${API_ANALYTICS}/recent`,
         method: 'GET',
         query,
      }
      return apiFactory(api)
   }

   static GetAnalyticsDistribution = async (payload) => {
      let api = {
         content_type: 'application/json',
         url: `${API_ANALYTICS}/distribution`,
         method: 'GET',
      }
      return apiFactory(api, payload)
   }

   static GetAnalyticsTrends = async (payload) => {
      let api = {
         content_type: 'application/json',
         url: `${API_ANALYTICS}/trends`,
         method: 'GET',
      }
      return apiFactory(api, payload)
   }

   static GetAnalyticsHeatmap = async (payload) => {
      let api = {
         content_type: 'application/json',
         url: `${API_ANALYTICS}/heatmap`,
         method: 'GET',
      }
      return apiFactory(api, payload)
   }
}
