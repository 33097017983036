import Hikvision from '../../../assets/images/vision_track/frame1.png'
import Unv from '../../../assets/images/vision_track/frame2.png'
import './index.css'

const getColor = (value) => {
	const { data = '' } = value

	if (data.toLowerCase() === 'configured') {
		return {
			color: '#088B3A',
			background: '#E7F7ED',
		}
	} else if (data.toLowerCase() === 'validating') {
		return {
			color: '#F4BC5E',
			background: '#FDF6DD',
		}
	} else if (data.toLowerCase() === 'not configured') {
		return {
			color: '#6C757D',
			background: '#F2F2F2',
		}
	} else {
		return {
			color: '#EB5842',
			background: '#FBE8D8',
		}
	}
}

const getImage = (value = '') => {
	if (value.toLowerCase() === 'hikvision') {
		return Hikvision
	} else if (value.toLowerCase() === 'unv') {
		return Unv
	} else {
		return 'other'
	}
}

const getSetupStatus = (value) => {
	const { data = '' } = value

	return (
		<div
			className="setup-status"
			style={{
				...getColor(value),
				minWidth: 'fit-content',
			}}
		>
			<span className="setup-text">{data}</span>
		</div>
	)
}

export const deviceColumns = [
	{
		label: 'Device-id',
		field: 'device_id',
		align: 'center',
	},
	{ label: 'Device Nickname', field: 'device_nickname', align: 'left' },
	{ label: 'Device Type', field: 'type', align: 'left' },
	{ label: 'Location', field: 'location', align: 'left' },
	{
		label: 'Brand',
		field: 'brand',
		align: 'left',
		cellComponent: ({ data }) =>
			data === 'Other' ? (
				<div>{data}</div>
			) : (
				<img alt={data} src={getImage(data)} />
			),
	},
	{
		label: 'Setup Status',
		field: 'setup_status',
		align: 'left',
		cellComponent: (value) => {
			return getSetupStatus(value)
		},
	},
	{ label: 'Actions', field: 'actions', align: 'right' },
]

export const brandData = [
	{
		label: 'HIKVISION',
		value: 'Hikvision',
	},
	{
		label: 'UNV',
		value: 'UNV',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

export const typeData = [
	{
		label: 'Camera',
		value: 'camera',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

export const spaceType = [
	{
		label: 'Entrance',
		title: 'Entrance',
		options: [
			{ label: 'Main Entrance', value: 'main_entrance' },
			{ label: 'Back Entrance', value: 'back_entrance' },
			{ label: 'Side Entrance', value: 'side_entrance' },
			{ label: 'Emergency Exit', value: 'emergency_exit' },
			{ label: 'Delivery Entrance', value: 'delivery_entrance' },
		],
	},
	{
		label: 'Interior',
		title: 'Interior',
		options: [
			{ label: 'Lobby', value: 'lobby' },
			{ label: 'Reception', value: 'reception' },
			{ label: 'Hallway', value: 'hallway' },
			{ label: 'Corridor', value: 'corridor' },
			{ label: 'Elevator', value: 'elevator' },
			{ label: 'Stairwell', value: 'stairwell' },
			{ label: 'Staircase', value: 'staircase' },
		],
	},
	{
		label: 'Rooms',
		title: 'Rooms',
		options: [
			{ label: 'Office', value: 'office' },
			{ label: 'Meeting Room', value: 'meeting_room' },
			{ label: 'Conference Room', value: 'conference_room' },
			{ label: 'Break Room', value: 'break_room' },
			{ label: 'Cafeteria', value: 'cafeteria' },
			{ label: 'Kitchen', value: 'kitchen' },
			{ label: 'Storage Room', value: 'storage_room' },
			{ label: 'Server Room', value: 'server_room' },
			{ label: 'Utility Room', value: 'utility_room' },
		],
	},
	{
		label: 'Parking',
		title: 'Parking',
		options: [
			{ label: 'Parking Lot', value: 'parking_lot' },
			{ label: 'Parking Entrance', value: 'parking_entrance' },
			{ label: 'Parking Exit', value: 'parking_exit' },
			{ label: 'Loading Dock', value: 'loading_dock' },
			{ label: 'Garage', value: 'garage' },
		],
	},
	{
		label: 'Perimeter',
		title: 'Perimeter',
		options: [
			{ label: 'Building Corner', value: 'building_corner' },
			{ label: 'Fence Line', value: 'fence_line' },
			{ label: 'Gate', value: 'gate' },
			{ label: 'Walkway', value: 'walkway' },
			{ label: 'Outdoor Area', value: 'outdoor_area' },
		],
	},
	{
		label: 'Floor Specific',
		title: 'Floor Specific',
		options: [
			{ label: 'Ground Floor', value: 'ground_floor' },
			{ label: 'Basement', value: 'basement' },
			{ label: 'Mezzanine', value: 'mezzanine' },
			{ label: 'Upper Floor', value: 'upper_floor' },
		],
	},
]

// export const spaceType = [
// 	{ value: 'outside', label: 'Outside' },
// 	{ value: 'inside', label: 'Inside' },
// 	{ value: 'room_1', label: 'Room 1' },
// 	{ value: 'etc', label: 'Etc' },
// ]

export const locationOptions = [
	{
		label: 'Mounting Height',
		title: 'Mounting Height',
		options: [
			{ label: 'Ceiling Mounted', value: 'ceiling_mounted' },
			{ label: 'Wall Mounted', value: 'wall_mounted' },
			{ label: 'Pole Mounted', value: 'pole_mounted' },
			{ label: 'Corner Mounted', value: 'corner_mounted' },
			{ label: 'Roof Mounted', value: 'roof_mounted' },
			{ label: 'Low Level', value: 'low_level' },
			{ label: 'Eye Level', value: 'eye_level' },
			{ label: 'High Level', value: 'high_level' },
		],
	},
	{
		label: 'Directional',
		title: 'Directional',
		options: [
			{ label: 'Facing North', value: 'facing_north' },
			{ label: 'Facing South', value: 'facing_south' },
			{ label: 'Facing East', value: 'facing_east' },
			{ label: 'Facing West', value: 'facing_west' },
			{ label: 'Facing Entrance', value: 'facing_entrance' },
			{ label: 'Facing Exit', value: 'facing_exit' },
			{ label: 'Facing Street', value: 'facing_street' },
			{ label: 'Facing Building', value: 'facing_building' },
		],
	},
	{
		label: 'Corner Position',
		title: 'Corner Position',
		options: [
			{ label: 'Top Right Corner', value: 'top_right_corner' },
			{ label: 'Top Left Corner', value: 'top_left_corner' },
			{ label: 'Bottom Right Corner', value: 'bottom_right_corner' },
			{ label: 'Bottom Left Corner', value: 'bottom_left_corner' },
			{ label: 'Right Corner', value: 'right_corner' },
			{ label: 'Left Corner', value: 'left_corner' },
		],
	},
	{
		label: 'Relative Position',
		title: 'Relative Position',
		options: [
			{ label: 'Above Door', value: 'above_door' },
			{ label: 'Beside Door', value: 'beside_door' },
			{ label: 'After Entrance', value: 'after_entrance' },
			{ label: 'Before Entrance', value: 'before_entrance' },
			{ label: 'Over Counter', value: 'over_counter' },
			{ label: 'Over Register', value: 'over_register' },
			{ label: 'Over Seating', value: 'over_seating' },
			{ label: 'Above Window', value: 'above_window' },
			{ label: 'Beside Window', value: 'beside_window' },
		],
	},
	{
		label: 'Coverage Area',
		title: 'Coverage Area',
		options: [
			{ label: 'Wide Angle', value: 'wide_angle' },
			{ label: 'Narrow Angle', value: 'narrow_angle' },
			{ label: 'Panoramic', value: 'panoramic' },
			{ label: 'Overhead View', value: 'overhead_view' },
			{ label: 'Eye Level View', value: 'eye_level_view' },
			{ label: 'Bird Eye View', value: 'bird_eye_view' },
			{ label: 'Diagonal View', value: 'diagonal_view' },
		],
	},
	{
		label: 'Location Context',
		title: 'Location Context',
		options: [
			{ label: 'Near Exit Sign', value: 'near_exit_sign' },
			{
				label: 'Near Fire Extinguisher',
				value: 'near_fire_extinguisher',
			},
			{
				label: 'Near Emergency Light',
				value: 'near_emergency_light',
			},
			{ label: 'Near Stairwell', value: 'near_stairwell' },
			{ label: 'Near Elevator', value: 'near_elevator' },
			{ label: 'Near Bathroom', value: 'near_bathroom' },
			{ label: 'Near Kitchen', value: 'near_kitchen' },
			{ label: 'Near Register', value: 'near_register' },
			{ label: 'Near Entrance', value: 'near_entrance' },
		],
	},
	{
		label: 'Mounting Type',
		title: 'Mounting Type',
		options: [
			{ label: 'Recessed Mount', value: 'recessed_mount' },
			{ label: 'Surface Mount', value: 'surface_mount' },
			{ label: 'Pendant Mount', value: 'pendant_mount' },
			{ label: 'Bracket Mount', value: 'bracket_mount' },
			{ label: 'Pole Mount', value: 'pole_mount' },
			{ label: 'Wall Arm Mount', value: 'wall_arm_mount' },
			{ label: 'Ceiling Grid Mount', value: 'ceiling_grid_mount' },
		],
	},
	{
		label: 'Height Reference',
		title: 'Height Reference',
		options: [
			{ label: 'Ground Plus 1m', value: 'ground_plus_1m' },
			{ label: 'Ground Plus 2m', value: 'ground_plus_2m' },
			{ label: 'Ground Plus 3m', value: 'ground_plus_3m' },
			{ label: 'Ground Plus 4m', value: 'ground_plus_4m' },
			{ label: 'Ground Plus 5m', value: 'ground_plus_5m' },
			{ label: 'Below Ceiling 1m', value: 'below_ceiling_1m' },
			{ label: 'Below Ceiling 2m', value: 'below_ceiling_2m' },
		],
	},
	{
		label: 'Building Section',
		title: 'Building Section',
		options: [
			{ label: 'Front Section', value: 'front_section' },
			{ label: 'Middle Section', value: 'middle_section' },
			{ label: 'Back Section', value: 'back_section' },
			{ label: 'East Wing', value: 'east_wing' },
			{ label: 'West Wing', value: 'west_wing' },
			{ label: 'North Wing', value: 'north_wing' },
			{ label: 'South Wing', value: 'south_wing' },
			{ label: 'Main Building', value: 'main_building' },
			{ label: 'Annex Building', value: 'annex_building' },
		],
	},
]

export const cameraBrands = [
	{ label: 'Hikvision', value: 'hikvision' },
	{ label: 'Dahua', value: 'dahua' },
	{ label: 'Axis Communications', value: 'axis' },
	{ label: 'Hanwha Techwin (Samsung)', value: 'hanwha' },
	{ label: 'Bosch Security', value: 'bosch' },
	{ label: 'Uniview', value: 'uniview' },
	{ label: 'Avigilon', value: 'avigilon' },
	{ label: 'Pelco', value: 'pelco' },
	{ label: 'Vivotek', value: 'vivotek' },
	{ label: 'Panasonic', value: 'panasonic' },
	{ label: 'Honeywell', value: 'honeywell' },
	{ label: 'CP Plus', value: 'cp_plus' },
	{ label: 'Tiandy', value: 'tiandy' },
	{ label: 'TVT', value: 'tvt' },
	{ label: 'ZKTeco', value: 'zkteco' },
	{ label: 'Mobotix', value: 'mobotix' },
	{ label: 'Reolink', value: 'reolink' },
	{ label: 'Lorex', value: 'lorex' },
	{ label: 'Swann', value: 'swann' },
	{ label: 'Custom/Other', value: 'custom' },
]


export const deviceStatusData = [
	{
		label: 'Configured',
		value: 'configured',
	},
	{
		label: 'Non-configured',
		value: 'non-configured',
	},
	{
		label: 'Disconnected',
		value: 'disconnected',
	},
	{
		label: 'Validating',
		value: 'validating',
	},
]

export const setupItems = [
	{ label: 'Setup status', value: 'setup_status' },
	{ label: 'Configured', value: 'configured' },
	{ label: 'Validating', value: 'validating' },
	{ label: 'Disconnected', value: 'disconnected' },
]
