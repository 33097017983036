import { isRetailVenue } from '../../../helpers/venueHelpers'
import { isEmpty } from '../../../utils/common'

export const columns = (productText, venueType, getCurrencySymbol) => [
	{
		label: productText('image'),
		field: 'image_path',
		align: 'left',
		cellComponent: ({ data }) => (
			<div>
				{isEmpty(data) ? (
					<div className="w-9 h-9 bg-gray6" style={{ borderRadius: '5px' }} />
				) : (
					<img
						src={data}
						style={{ borderRadius: '5px' }}
						className="w-9 h-9"
						alt="preview"
					/>
				)}
			</div>
		),
	},
	{ label: productText('title'), field: 'title', align: 'left' },
	...(isRetailVenue(venueType)
		? [
			{
				label: productText('article_no'),
				field: 'article_no',
				align: 'left',
			},
		]
		: []),
	{
		label: productText('description'),
		field: 'description',
		align: 'left',
		cellComponent: ({ data }) => {
			return (
				<div>
					{data?.length > 25 ? data.substring(0, 25) + '...' : data ?? ''}
				</div>
			)
		},
	},
	{ label: productText('brand_name'), field: 'brand_name', align: 'left' },

	{
		label: productText('price'),
		field: 'price',
		align: 'left',
		cellComponent: ({ data, row }) => (
			<div>
				{data} {getCurrencySymbol(row?.currency) ?? ''}
			</div>
		),
	},

	{ label: productText('stock'), field: 'stock_quantity', align: 'left' },
	{ label: productText('type'), field: 'product_type', align: 'left', cellComponent: ({ data }) => <div>{data == "variable" ? "Variations" : "Single"}</div> },
	// { label: productText('variations'), field: 'product_type', align: 'left', cellComponent: ({ data }) => <div className='capitalize'>{data}</div>},
	{
		label: productText('status'),
		field: 'available',
		align: 'left',
		cellComponent: ({ data }) => (
			<div className={Number(data) === 1 ? 'confirm-gold' : 'cancelled-gold'}>
				<span className="text-white text-[12px]">
					{Number(data) === 1 ? 'Active' : 'Inactive'}
				</span>
			</div>
		),
	},
	{ label: productText('actions'), field: 'actions', align: 'left' },
]
