// auth
export const API_LOGIN = 'auth/login';
export const API_LOGOUT = 'auth/logout';
export const API_TOKEN_REFRESH = 'auth/refresh';
export const API_USER_PROFILE = 'users';

// reservations
export const API_RESERVATION = 'reservations';
export const API_RESERVATION_FILTER = 'reservations/filter';

// waitlist
export const API_WAITLIST = 'waitlists';
export const API_PRIORITIZE_GUEST = 'waitlists/prioritize';
export const API_WAITLIST_GUEST = 'waitlists/guests';
export const API_UPDATE_WAIT_TIME = 'waitlists/update-wait-time';

// tables
export const API_TABLE = 'tables';
export const API_TABLE_DETAILS = 'tables/details';
export const API_CREATE_TABLE = 'tables';
export const API_TABLE_AVAILABILITY = 'tables/availability';
export const API_SEATING_ARRANGEMENTS = 'tables/seating-arrangements';
export const API_CREATE_SEATING_ARRANGEMENT = 'tables/seating-arrangements';
export const API_LOCATIONS = 'tables/dining-space-locations';
export const API_SHAPE = 'tables/shapes';
export const API_TABLE_AVAILABLE = 'tables/available';
export const API_TABLE_MERGE = 'tables/merge';
export const API_TABLE_SPLIT = 'tables/split';
export const API_TABLE_MOVE = 'tables/move';
export const API_TABLE_ASSIGN_GUESTS = 'tables/assign-guests';

// staff
export const API_EMPLOYEE = 'staff/employees';
export const API_STAFF = 'staff/employees-staff';
export const API_PAYROLL = 'staff/payroll';
export const API_SCHEDULE = 'staff/schedules';
export const API_SCHEDULE_CONFLICT = 'staff/view-schedule-conflicts';
export const API_ROLES = 'staff/roles';
export const API_GENERATE_REPORTS = 'staff/reports/generate';
export const API_SCHEDULE_REQUESTS = 'staff/view-schedule-to-requests';
export const API_APPROVE_TIME_OFF = 'staff/approve-time-off';
export const API_VIEW_ALL_SCHEDULE_REQUESTS = 'staff/view-schedule-to-requests';

// marketing
export const API_PROMOTIONS = 'promotions';
export const API_PROMOTIONS_CALENDAR = 'promotions/calendar'
export const API_DISCOUNTS = 'promotions/discounts'
export const API_COUPONS = 'promotions/coupons'
export const API_ACTIVE_DISCOUNTS = 'promotions/active-discounts'

export const API_CAMPAIGNS = 'campaigns';

export const API_END_USER_CARD = 'end-user-card';
export const API_END_USER_CARD_CHANGE_STATUS = 'end-user-card/change-status';
export const API_END_USER_CARD_CHANGE_VERIFY = 'end-user-card/verify';

// hygiene standard
export const API_HYGIENE_STANDARD = 'hygiene-standard';
export const API_HYGIENE_STANDARD_CHECKS = 'hygiene-standard/hygiene-checks';
export const API_HYGIENE_STANDARD_CHECKLIST_ITEM = 'hygiene-standard/checklist-items';
export const API_HYGIENE_STANDARD_INSPECTIONS = 'hygiene-standard/hygiene-inspections';
export const API_HYGIENE_STANDARD_VENDOR = 'hygiene-standard/vendors';

// guests
export const API_GUESTS = 'guests';
export const API_DEVICES = 'vision-track/devices';
export const API_ANALYTICS = 'vision-track/analytics';
export const DETECTION_ACTIVITIES = 'vision-track/detection-activities';
export const API_STREAMS = 'streams';
export const API_GUESTS_WITHOUT_TABLE = 'guests/no-table-reservations';

// menu
export const API_MENU = 'menu/bb-menu';
export const API_TRY_HOME = 'menu/products/try-home-product'
export const API_MENU_CATS = 'menu/categories';
export const API_MENU_PRODUCTS = 'menu/products';
export const API_SALES = 'sales'
export const API_MENU_RETAIL_INVENTORY = 'menu/retail-inventory';
export const API_MENU_ATTRIBUTES = 'menu/product-attributes';
export const API_MENU_VARIATIONS = 'menu/product-variations';
export const API_MENU_COLLECTIONS = 'menu/collections';
export const API_MENU_ATT_VARIATION = 'menu/product-att-variations';
export const API_MENU_UPLOAD_PHOTO = 'menu/upload-photo';
export const API_MENU_RETAIL_INVENTORIES = 'menu/inventories';
export const API_MENU_RETAIL_INVENTORIES_ACTIVITY = 'menu/inventory-activity';

// inventory
export const API_INVENTORY = 'inventory';
export const API_SYNCHRONIZATIONS = 'synchronizations'
export const API_INVENTORY_MANAGEMENT = 'inventory-management';
export const API_SALES_METRICS = 'sales-metrics';

// order
export const API_ORDERS = 'orders';

// report
export const API_REPORT_SALES = 'reports/sales-by-product-report';
export const API_REPORT_PERFORMANCE = 'staff/performance';
export const API_REPORT_EXPENSE = 'staff/expense';
export const API_REPORT_WAITLIST = 'reports/waitlist';
export const API_REPORT_TABLE = 'reports/table-metrics';
export const API_REPORTS = 'reports'; 

export const API_MARKETING = 'marketing'; 
export const API_REFERRALS = 'marketing/referrals'; 

// loyalty
export const API_PROGRAMS = 'loyalty/programs';
export const API_ENROLLED_GUESTS = 'loyalty/programs/enrolled-guests';

// web profile
export const API_WEB_PROFILE = 'web-profile';
export const API_WHITE_LABEL = 'whitelabel-customization';
export const API_BRAND_PROFILE = 'brand-profile-customization';

// account
export const API_ACCOUNTS = {
    content_type: 'application/json',
    url: 'accounts'
}

export const API_ACCOUNTS_EMAIL = 'accounts/update-email-preferences';

export const API_PAYMENT_LINKS= 'payment-links';
export const API_ORDERS_AND_PAY = 'orders-and-pay';

export const API_DASHBOARD = 'dashboard';

export const API_VENUE = 'venue';
export const API_TEMPLATE = 'templates';
export const API_CUSTOMERS = 'customers';
export const API_MEMBERS = 'members';
export const API_FEATURE_FEEDBACK = 'feature-feedback';
export const API_CHAT = 'chat'

// retail
export const API_RETAIL = 'retail';
export const API_STORE_SETTINGS = 'retail/store-settings/';
export const API_WAREHOUSE = 'inventory-configurations/warehouses';
export const API_PHYSICAL_STORES = 'inventory-configurations/physical-stores';
export const API_ECOMM_PLATFORMS = 'inventory-configurations/ecommerce-platforms';

// blogs
export const API_BLOGS = 'blog';
export const API_BLOG_CATEGORIES = 'blog-categories';

// accommodation
export const API_ACCOMMODATION = 'accommodation';
// housekeeping tasks
export const API_HOUSEKEEPING_TASKS = 'housekeeping-tasks';

// gym
export const API_GYM_CLASSES = 'gym/classes';

// golf
export const API_GOLF_COURSE_TYPES = 'golf/course-types';

// Beach bar

export const API_BEACHBAR = 'beach-bar'

// Ingredients

export const API_INGREDIENTS = 'ingredients'


// Brands

export const API_BRANDS = 'brands'


// bowling
export const API_BOWLING_LANES = 'bowling/lanes';

// subscriptions
export const API_SUBSCRIPTION_CREATE_CHECKOUT_SESSION = 'subscriptions/create-checkout-session';
export const API_SUBSCRIPTION_CONFIRM_SUBSCRIPTION = 'subscriptions/confirm-subscription';
export const API_SUBSCRIPTION_GET_SUBSCRIPTION = 'subscriptions/get-subscription';

//bookin

export const API_BOOKING = 'booking';

// Terminal Connection

export const TERMINAL_CONNECTION = 'web-stripe-terminal/connection'

export const CREATE_PAYMENT_INTENT = 'web-stripe-terminal/payments'


// Store integrations
export const API_STORE_INTEGRATIONS = 'store-integrations';

// Product templates
export const API_TEMPLATES = 'product-templates'
