import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import {
	Checkbox,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import React from 'react'
import AccommodationVacationRentalStandardIcon from '../../assets/svgs/accommodation-vacation-standard.svg'
import { reservationsText } from '../../views/reservations'
import { chatIcon, deleteIcon, downloadIcon, eyeIcon, pencilIcon, trashIcon } from './icons'
import './index.css'

const sx = {
	minWidth: 350,
	'& .MuiTableCell-root': {
		border: 'none',
		backgroundColor: 'transparent',
		paddingLeft: 2,
		paddingRight: 0,
	},
	'& .MuiTableRow-root': {
		minHeight: '24px',
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},
}

const GenericTable = ({
	data = [],
	columns = [],
	onEdit,
	onDelete,
	onStream,
	onView,
	onChat,
	onDetails,
	onDownload,
	onDelivery,
	onTrash,
	className = '!pt-[23px]',
	onDoubleClick = false,
	onConfirm,
	onSelectRow,
	selectedRows = [],
	showCheckbox = false,
	activeSubmenu,
	submenuComponent,
	customActions,
	justifyEnd = true,
	alignRight = false
}) => {
	const columnClass =
		`!text-[#232323] cell-text !flex ${justifyEnd ? '!justify-end' : '!justify-start'} !items-center gap-2 !w-full`

	const isSelected = (id) => selectedRows.includes(id)
	return (
		<TableContainer
			component={Paper}
			className={`!mt-0 table-container border-none ${className}`}
		>
			<Table sx={sx} aria-label="simple table">
				<TableHead className="!bg-[#F9F9F9]">
					<TableRow>
						{showCheckbox && (
							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={
										selectedRows.length > 0 && selectedRows.length < data.length
									}
									checked={
										data.length > 0 && selectedRows.length === data.length
									}
									onChange={(e) =>
										onSelectRow(
											e.target.checked ? data.map((row) => row.id) : []
										)
									}
								/>
							</TableCell>
						)}
						{columns.map((column, index) => (
							<TableCell
								key={index}
								className={`head-cell !font-medium ${(column.field === 'actions' || column.field === "total_points_earned" || alignRight) && index === columns.length - 1
									? '!w-full !flex !justify-end items-center !text-right'
									: '!text-left'
									}`}
								align={
									(column.field === 'actions' || alignRight) && index === columns.length - 1
										? ''
										: column.align
								}
								width={column?.width}
							>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row) => (
						<>
							<TableRow
								key={row.id}
								className={`!w-1/2 ${onDoubleClick ? 'cursor-pointer' : ''}`}
								onDoubleClick={() => onDoubleClick && onView(row)}
							>
								{showCheckbox && (
									<TableCell padding="checkbox">
										<Checkbox
											checked={isSelected(row.id)}
											onChange={() => onSelectRow(row.id)}
										/>
									</TableCell>
								)}
								{columns.map((column, colIndex) => {
									const fieldParts = column.field.split('.')
									const value = fieldParts.length > 1 ? row[fieldParts[0]][fieldParts[1]] : row[column.field]

									if (typeof value === 'object' && value !== null && column.field !== "actions") {
										return (
											<TableCell
												key={colIndex}
												align={column.align}
												className={`cell-text !h-5 w-fit ${colIndex === columns.length - 1
													? columnClass
													: '!text-left'
													}`}
												width={column?.width}
											>
												{/* Empty cell */}
											</TableCell>
										);
									}


									if (column.field === 'actions') {
										return (
											<TableCell
												key={colIndex}
												align="left"
												className={columnClass}
												width={column?.width}

											>
												{customActions && customActions(row)}
												{onView && !onDoubleClick && (
													<div
														className={`icon-wrapper cursor-pointer ${submenuComponent ? "border rounded-[4px] !px-3 !py-1 !w-[85px] !h-fit !justify-start" : ""}`}
														onClick={() => onView(row)}
													>
														<span>{eyeIcon}</span>
														{submenuComponent && <span>{activeSubmenu === row.id ? "Hide" : "Show"}</span>}
													</div>
												)}
												{onDetails && (
													<div className="flex justify-center cursor-pointer">
														<IconButton
															color="secondary"
															onClick={() => onDetails(row)}
														>
															<img
																src={AccommodationVacationRentalStandardIcon}
																alt="date"
																className="w-5 h-5"
															/>
														</IconButton>
													</div>
												)}
												{onEdit && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onEdit(row)}
													>
														<span>{pencilIcon}</span>
													</div>
												)}
												{onChat && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onChat(row)}
													>
														<span>{chatIcon}</span>
													</div>
												)}
												{onDelete && !row?.is_confirmed && !row?.is_cancelled && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onDelete(row.id)}
													>
														<span>{deleteIcon}</span>
													</div>
												)}
												{onConfirm &&
													!row?.is_confirmed &&
													!row?.is_cancelled && (
														<div
															className="icon-wrapper cursor-pointer"
															onClick={() => onView(row)}
														>
															<span>{eyeIcon}</span>
														</div>
													)}


												{onStream && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onStream(row)}
													>
														<span>{eyeIcon}</span>
													</div>
												)}
												{onDownload && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onDownload(row)}
													>
														<span>{downloadIcon}</span>
													</div>
												)}
												{onDelivery && (
													<div
														className="icon-wrapper cursor-pointer"
														onClick={() => onDelivery(row)}
													>
														<span>{<LocalShippingIcon fontSize="18px" />}</span>
													</div>
												)}
												{onTrash &&
													!row?.is_confirmed &&
													!row?.is_cancelled && (
														<div
															className="icon-wrapper cursor-pointer"
															onClick={() => onTrash(row.id)}
														>
															<span>{trashIcon}</span>
														</div>
													)}
												{row?.is_confirmed === true && (
													<div className="confirm-gold">
														<span className="text-white text-[9px]">
															{reservationsText('confirmed')}
														</span>
													</div>
												)}
												{row?.is_cancelled === true && !row?.is_confirmed && (
													<div className="cancelled-gold">
														<span className="text-white text-[9px]">
															{reservationsText('cancelled')}
														</span>
													</div>
												)}
											</TableCell>
										)
									}
									if (column.cellComponent) {
										const CustomComponent = column.cellComponent
										return (
											<TableCell
												key={colIndex}
												align={column.align}
												className="cell-text !text-left"
											>
												<CustomComponent data={row[column.field]} />
											</TableCell>
										)
									}
									return (
										<TableCell
											key={colIndex}
											align={column.align}
											className={`cell-text !h-5 w-fit ${colIndex === columns.length - 1
												? columnClass
												: '!text-left'
												}`}
											width={column?.width}
										>
											{value}
										</TableCell>
									)
								})}
							</TableRow>
							{Boolean(submenuComponent && activeSubmenu === row.id) && (
								<TableRow className="!w-1/2">
									<TableCell colSpan={columns.length}>
										{submenuComponent(row)}
									</TableCell>
								</TableRow>
							)}
						</>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default GenericTable
