import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
	cancelIcon,
	pencilIcon,
	saveIcon,
} from './icons';
import './index.css';

const sx = {
	minWidth: 350,
	'& .MuiTableCell-root': {
		border: 'none',
		backgroundColor: 'transparent',
		paddingLeft: 2,
		paddingRight: 0,
	},
	'& .MuiTableRow-root': {
		minHeight: '24px',
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},
};

const EditableGenericTable = ({
	data = [],
	columns = [],
	onSave,
	className = '!pt-[23px]',
	alignRight = false,
}) => {
	const [editingRow, setEditingRow] = useState(null); // Track which row is being edited
	const [editedData, setEditedData] = useState({}); // Store edited data for the selected row

	const handleEditClick = (row) => {
		setEditingRow(row.id); // Mark this row as editable
		setEditedData(row); // Initialize edited data with current row
	};

	const handleSaveClick = () => {
		if (onSave) onSave(editedData); // Send edited data to the parent component
		setEditingRow(null); // Exit editing mode
		setEditedData({}); // Clear edited data
	};

	const handleCancelClick = () => {
		setEditingRow(null); // Exit editing mode without saving
		setEditedData({}); // Reset edited data
	};

	const handleFieldChange = (field, value) => {
		setEditedData((prev) => ({ ...prev, [field]: value })); // Update specific field
	};

	return (
		<TableContainer component={Paper} className={`!mt-0 table-container border-none ${className}`}>
			<Table sx={sx} aria-label="editable table">
				<TableHead className="!bg-[#F9F9F9]">
					<TableRow className='grid grid-cols-5'>
						{columns.map((column, index) => (
							<TableCell
								key={index}
								className={`head-cell !font-medium !text-center`}
								width={column?.width}
							>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row) => (
						<TableRow key={row.id} className=''>
							{columns.map((column, colIndex) => (
								<TableCell key={colIndex} align={column.align} >
									{/* If it's an editable column and the row is in edit mode */}
									{editingRow === row.id && column.editable ? (
										<TextField
											value={editedData[column.field] == 0 ? "0" : editedData[column.field] || ''}
											onChange={(e) => handleFieldChange(column.field, e.target.value)}
											size="small"
										// classes={{ root: 'w-1/2' }}
										/>
									) : column.field === 'actions' ? (
										editingRow === row.id ? (
											<div className="flex">
												<IconButton onClick={handleSaveClick} color="primary">
													{saveIcon}
												</IconButton>
												<IconButton onClick={handleCancelClick} color="secondary">
													{cancelIcon}
													</IconButton>
												</div>
										) : (
											<IconButton onClick={() => handleEditClick(row)} color="primary">
												{pencilIcon}
											</IconButton>
										)
									) : column.cellComponent ? (
										/* Render custom cell content */
										column.cellComponent({ data: row[column.field] })
									) : (
										/* Default content for non-editable cells */
										row[column.field]
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default EditableGenericTable;
