import { Card, CardContent, Tooltip } from '@mui/material'
import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import plusIcon from '../../../assets/svgs/plus.svg'
import shopifyIcon from '../../../assets/svgs/shopify.svg'
import uploadIcon from '../../../assets/svgs/upload.svg'
import woocommerceIcon from '../../../assets/svgs/woocommerce.svg'
import useDebounce from '../../../components/Hooks/useDebounce'
import { BrandFilter } from '../../../components/common/BrandFilter'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import InventoryAlertModal from '../../../components/inventory/InventoryAlertModal'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { isRetailVenue } from '../../../helpers/venueHelpers'
import MenuService from '../../../services/menuService'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import { isActionAllowed } from '../data'
import { menuText } from '../menus'
import ProductModal from './ProductModal'
import BulkImportModal from './bulkImportModal'
import ChooseProductType from './choose-product-type'
import { columns } from './columns'
import ShopifyModal from './shopifyModal'
import TryAtHomeModal from './try-at-home-modal'
import WooCommerceModal from './woocommerceModal'

export const productText = (text) => t(`menu_management.products.${text}`)

export const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

const getCurrencySymbol = (currencyCode) => {
	return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
}

export default function MenuProducts() {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showImportModal, setShowImportModal] = useState(false)
	const [showTryHome, setShowTryHome] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false)
	const [selectedProduct, setProductSelected] = useState({})
	const [search, setSearch] = useState('')
	const debouncedValue = useDebounce(search, 900)

	const [visibleShopify, setVisibleShopify] = useState(false)
	const [visibleWoocommerce, setVisibleWoocommerce] = useState(false)
	const [showAlertModal, setShowAlertModal] = useState(false)
	const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const [openType, setOpenType] = useState(false)
	const [filter, setFilter] = useState('All Brands')
	const isAllowedFromBackend = false
	const [totalCnt, setTotalCnt] = React.useState(0)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [debouncedValue, rowsPerPage, page])

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (value) => {
		setRowsPerPage(value)
		setPage(0)
	}

	const handleTryAtHomeClick = (row) => {
		setShowTryHome(true)
		setProductSelected(row)
	}

	const onCloseTryHome = () => {
		setShowTryHome(false)
		setProductSelected(undefined)
	}

	function stripHtml(html) {
		const doc = new DOMParser().parseFromString(html, "text/html");
		return doc.body.textContent || doc.body.innerText;
	}

	const refreshData = () => {
		let query = [`per_page=${rowsPerPage}&page=${page + 1}`]
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		MenuService.getProducts(query.join('&'))
			.then((res) => {
				setLoaded(true)
				setData(
					res?.products?.length > 0
						? res?.products?.map((item) => ({
							...item,
							description: stripHtml(item.description) || '',
						}))
						: []
				);
				setTotalCnt(res?.pagination?.total ?? 0)
				setIsButtonDisabled(res?.hasUsedAllCredits)
				identifyVenue(short_code)
				trackEvent('Products List', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
				console.log('get product', res)
			})
			.catch((err) => {
				setLoaded(true)
				identifyVenue(short_code)
				trackEvent('Products List', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'product',
				})
			})
	}

	const onDelete = (id) => {
		setProductSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	const onChooseProductType = () => {
		setOpenType(true)
	}

	const onCreate = useCallback(() => {
		identifyVenue(short_code)
		// Track create product event
		trackEvent('Create Product', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})
		navigate(`/${short_code}/admin/menu/items/create`)
		// eslint-disable-next-line
	}, [])

	const onComposedCreate = useCallback(() => {
		identifyVenue(short_code)
		trackEvent('Create Composed Product', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})
		navigate(`/${short_code}/admin/menu/items/composed/create`)
		// eslint-disable-next-line
	}, [])

	const onVariationsCreate = useCallback(() => {
		identifyVenue(short_code)
		trackEvent('Create Composed Product', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})
		navigate(`/${short_code}/admin/menu/items/variations/create`)
		// eslint-disable-next-line
	}, [])

	const onEdit = useCallback((item) => {
		identifyVenue(short_code)
		// Track update product event
		trackEvent('Update Product', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})
		navigate(`/${short_code}/admin/menu/items/edit/${item?.id}`)
		// eslint-disable-next-line
	}, [])

	const onGoSync = () => {
		navigate(`/${short_code}/admin/inventory-management/synchronizations`)
	}

	const onSubmit = () => {
		MenuService.delProduct(selectedProduct)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				refreshData()
				NotificationManager.success(
					data?.message ?? productText('product_deleted_successfully')
				)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'products',
				})
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || t('common.error_generic')
						)
					} else {
						NotificationManager.error(t('common.error_generic'))
					}
				}
			})
	}

	return (
		<div className='h-full'>
			{showTryHome && (
				<TryAtHomeModal
					onClose={onCloseTryHome}
					open={showTryHome}
					product={selectedProduct}
					refreshData={refreshData}
				/>
			)}
			{loaded ? (
				<div className="flex flex-col p-6">
					<Card className=''>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{menuText('Products')}
									</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{data.length} {menuText('products')}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										gap: '16px',
										alignItems: 'flex-end',
									}}
								>
									{isRetailVenue(venueType) && isActionAllowed(short_code) && (
										<div className="flex flex-row items-center gap-4">
											<Tooltip title={productText('import_products')}>
												<div
													className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer}`}
													onClick={() => setShowImportModal(true)}
												>
													<img
														src={uploadIcon}
														alt="plus"
														className="mr-2 cursor-pointer"
													/>
													<span className="cursor-pointer">
														{productText('bulk_import')}
													</span>
												</div>
											</Tooltip>
										</div>
									)}
									{isRetailVenue(venueType) && isAllowedFromBackend && (
										<div className="flex flex-row items-center gap-4 mr-4">
											<img
												src={shopifyIcon}
												alt="shopify"
												className="cursor-pointer w-10"
												onClick={() => setVisibleShopify(true)}
											/>
											<img
												src={woocommerceIcon}
												alt="woocommerce"
												className="cursor-pointer w-10"
												onClick={() => setVisibleWoocommerce(true)}
											/>
										</div>
									)}
									<div className="flex flex-row border rounded-lg px-3">
										<input
											className=" w-[220px] max-w-[80vw] h-[40px] px-2 outline-none"
											placeholder={productText('search_products')}
											value={search}
											onChange={(e) => {
												setSearch(e.target.value)
											}}
										/>
									</div>
									<BrandFilter onBrandChange={setFilter} />
									{isRetailVenue(venueType) && (
										<div
											className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer whitespace-nowrap`}
											onClick={onGoSync}
										>
											<span>{productText('sync_manual')}</span>
										</div>
									)}

										<Tooltip
											title={
												isButtonDisabled
													? productText('plan_credits_utilized')
													: ''
											}
										>
											<div
											className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg text-nowrap w-full cursor-pointer ${
													isButtonDisabled
														? 'opacity-50 cursor-not-allowed'
														: ''
												}`}
												onClick={!isButtonDisabled ? onChooseProductType : null}
											>
												<img src={plusIcon} alt="plus" className="mr-2" />
												<span>{productText('add_product')}</span>
											</div>
										</Tooltip>

								</div>
							</div>

							{data.length ? (
								<>
									<div className="w-full flex flex-col">
										<GenericTable
											data={data}
											columns={columns(
												productText,
												venueType,
												getCurrencySymbol
											)}
											onEdit={onEdit}
											onDelete={onDelete}
											{...(short_code !== "BYB2929SCDE" ? { onView: handleTryAtHomeClick } : {})}

										/>
									</div>

									<GenericPagination
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										page={page}
										rowsPerPage={rowsPerPage}
										setPage={setPage}
										count={totalCnt}
									/>
								</>
							) : (
								<TableEmptyData
									content={productText('products')}
									onAction={() => onChooseProductType()}
									btnLabel={
										isActionAllowed(short_code)
											? productText('add_product')
											: ''
									}
								/>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
			{showModal && (
				<ProductModal
					showModal={showModal}
					onClose={() => setShowModal(false)}
				/>
			)}

			{showImportModal && (
				<BulkImportModal
					showImportModal={showImportModal}
					setShowImportModal={setShowImportModal}
					onClose={() => setShowImportModal(false)}
				/>
			)}

			{openType && (
				<ChooseProductType
					onClose={() => setOpenType(false)}
					{...{ onCreate, onComposedCreate, onVariationsCreate }}
					open={openType}
				/>
			)}

			{isAreYouSureDeleteModalOpen && (
				<AreYouSureModal
					title={productText('delete_product')}
					content={productText('confirm_delete_product')}
					firstButton={productText('no_go_back')}
					secondButton={productText('yes_i_am')}
					showModal={isAreYouSureDeleteModalOpen}
					onClose={() => setAreYouSureDeleteModalOpen(false)}
					onSubmit={onSubmit}
				/>
			)}

			{visibleShopify && (
				<ShopifyModal
					showModal={visibleShopify}
					onClose={() => setVisibleShopify(false)}
				/>
			)}

			{visibleWoocommerce && (
				<WooCommerceModal
					showModal={visibleWoocommerce}
					onClose={() => setVisibleWoocommerce(false)}
				/>
			)}

			{showAlertModal && (
				<InventoryAlertModal
					title="Inventory Alert"
					showModal={showAlertModal}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowAlertModal(false)
					}}
				/>
			)}
		</div>
	)
}
