import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { orderTransformerV2, ordersText } from '../../../../views/delivery/orders'
import UpdateOrderStatusModal from '../../../../views/delivery/orders/update-order-status-modal'
import { columnsRenderTable } from '../../../../views/delivery/tables/columns'
import AreYouSureModal from '../../../common/areYouSureModal'
import GenericPagination from '../../../generic-table/generic-pagination'
import GenericTable from '../../../generic-table/generic-table'
import { OrdersColumns } from './data'
const OrdersTable = ({ data = [] }) => {
	const navigate = useNavigate()

	const { short_code } = useSelector((state) => state.app);
	const isBBShop = short_code === "BYB2929SCDE";

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [orderSelected, setOrderSelected] = useState();
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false);
	const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState();



	const onAction = (id) => {
		navigate(`/${short_code}/admin/delivery/order/${id}`)
	}


	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event)
		setPage(0)
	}

	const onDelete = (id) => {
		setOrderSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	const onEdit = (id) => {
		setOrderSelected(id)
		setIsUpdateStatusModalOpen(true)
	}

	const handleDeleteOrder = () => {

	}

	const handleStatusUpdate = () => {
		// const payload = {
		// 	status: status,
		// }

		// retailService.ChangeOrderStatus(orderId, payload)
		// 	.then(({ data }) => {
		// 		NotificationManager.success(data?.message ?? "Status change successfully");
		// 	})
		// 	.catch(err => {
		// 		NotificationManager.error(err?.error ?? "Something went wrong!");
		// 	})
	}

	const onDelivery = () => {

	}

	const BBShopColumns = columnsRenderTable(orderTransformerV2, false, true).map(item => {
		if (item.field === "customer_full_name") {
			return { ...item, field: "customer_name" }
		}
		return item;
	})

	const BBShopActions = isBBShop ? {
		onView: (item) => onAction(item.id),
		onTrash: onDelete,
		onEdit: onEdit,
		onDelivery: onDelivery,
	} : {};

	const displayedData = useMemo(() => {
		const start = page * rowsPerPage
		const end = start + rowsPerPage
		return data.slice(start, end)
	}, [data, page, rowsPerPage])

	return (
		<>
			<GenericTable
				columns={isBBShop ? BBShopColumns : OrdersColumns}
				data={displayedData}
				{...BBShopActions}
			/>
			<GenericPagination
				rowsPerPage={rowsPerPage}
				page={page}
				setPage={setPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				count={data.length}
				onPageChange={handleChangePage}
			/>
			{isAreYouSureDeleteModalOpen && (
				<AreYouSureModal
					title={ordersText('delete_order')}
					content={ordersText('delete_order_content')}
					firstButton={ordersText('no_go_back')}
					secondButton={ordersText('yes_i_am')}
					showModal={isAreYouSureDeleteModalOpen}
					onClose={() => setAreYouSureDeleteModalOpen(false)}
					onSubmit={handleDeleteOrder}
				/>
			)}
			{isUpdateStatusModalOpen && orderSelected?.status &&
				<UpdateOrderStatusModal title={ordersText("update_status")}
					onClose={() => setIsUpdateStatusModalOpen(false)}
					showModal={isUpdateStatusModalOpen}
					initialValue={orderSelected.status}
					onSubmit={handleStatusUpdate}
				/>
			}
		</>

	)
}

export default OrdersTable
