import { MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React from 'react'
import { arrowLeftIcon, arrowRightIcon } from './icons'
import './index.css'

const PaginationContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	padding: '0 16px',
})

const PaginationActions = styled('div')({
	display: 'flex',
	alignItems: 'center',
})

const PageButton = styled(Button)(({ theme }) => ({
	minWidth: '40px',
	height: '40px',
	margin: '0 5px',
	borderRadius: '50%',
	color: theme.palette.text.primary,
	'&.Mui-selected': {
		backgroundColor: '#4c1d95',
		color: '#ffffff',
	},
}))

const GenericPagination = ({
	count = 256,
	page,
	rowsPerPage,
	onPageChange,
	onRowsPerPageChange,
	setPage,
	px = '!pr-[23px] ',
	menuItems = [10, 25, 50],
}) => {
	const handleChangePage = (event, newPage) => {
		onPageChange(event, newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		onRowsPerPageChange(event.target.value)
		setPage(0)
	}

	const pageCount = Math.ceil(count / rowsPerPage)

	const renderPageNumbers = () => {
		const pages = []
		const maxDisplayedPages = 7 // Total number of page buttons including ellipses

		if (pageCount <= maxDisplayedPages) {
			for (let i = 0; i < pageCount; i++) {
				pages.push(i)
			}
		} else {
			const leftSiblingIndex = Math.max(page - 1, 1)
			const rightSiblingIndex = Math.min(page + 1, pageCount - 2)

			const shouldShowLeftEllipsis = leftSiblingIndex > 1
			const shouldShowRightEllipsis = rightSiblingIndex < pageCount - 2

			if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
				for (let i = 0; i < 3; i++) {
					pages.push(i)
				}
				pages.push('...')
				pages.push(pageCount - 1)
			} else if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
				pages.push(0)
				pages.push('...')
				for (let i = pageCount - 3; i < pageCount; i++) {
					pages.push(i)
				}
			} else if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
				pages.push(0)
				pages.push('...')
				for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
					pages.push(i)
				}
				pages.push('...')
				pages.push(pageCount - 1)
			} else {
				for (let i = 0; i < pageCount; i++) {
					pages.push(i)
				}
			}
		}

		return pages
	}

	return (
		<PaginationContainer>
			<div
				className={`pagination-container flex flex-col sm:flex-row justify-between w-full items-start sm:items-center pb-0 sm:pb-3`}
			>
				<div className="text-[#6c6c6c] text-[14px] max-w-sm:w-full mb-2 sm:mb-0 text-center">
					{`Showing ${page * rowsPerPage + 1} to ${Math.min(
						(page + 1) * rowsPerPage,
						count
					)} of ${count} entries`}
				</div>

				<PaginationActions>
					<PageButton
						onClick={(e) => handleChangePage(e, page - 1)}
						disabled={page === 0}
					>
						<span>{arrowLeftIcon}</span>
					</PageButton>
					{renderPageNumbers().map((pageNumber, index) => (
						<PageButton
							key={index}
							onClick={(e) => handleChangePage(e, pageNumber)}
							className={`${pageNumber === page ? 'selected-page' : ''} ${
								typeof pageNumber === 'number' ? 'text-black1' : ''
							}`}
							disabled={typeof pageNumber !== 'number'}
						>
							{typeof pageNumber === 'number' ? pageNumber + 1 : pageNumber}
						</PageButton>
					))}
					<PageButton
						onClick={(e) => handleChangePage(e, page + 1)}
						disabled={page >= pageCount - 1}
					>
						<span>{arrowRightIcon}</span>
					</PageButton>
				</PaginationActions>

				{/* Show rows per page selector */}
				{onRowsPerPageChange && (
					<div className="showRows flex items-center gap-1">
						<span className="text-[#6c6c6c] text-[14px]">Show</span>
						<Select
							style={{ height: '32px' }}
							value={rowsPerPage}
							onChange={handleChangeRowsPerPage}
						>
							{menuItems.map((item, index) => (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							))}
						</Select>
						<span className="text-[#6c6c6c] text-[14px]">Entries</span>
					</div>
				)}
			</div>
		</PaginationContainer>
	)
}

export default GenericPagination
