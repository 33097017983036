import React, { useState } from 'react'
import { devicesViewText } from '.'
import SelectFilter from '../../../components/dropdowns/select-filter'
import useIsMobile from '../../../helpers/is-mobile-hook'
import { plusIcon, searchIcon } from '../../guests/guests/icons'
import '../../guests/guests/index.css'
import { cameraBrands } from './data'

const DeviceFilters = ({
	setSearch,
	search,
	setOpen,
	selectedFilter,
	setSelectedFilter,
	setSelectedDevice,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const isMobile = useIsMobile()

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const onSelectFilter = (tier) => {
		setSelectedFilter(tier)
		setDropdownOpen(false)
	}

	return (
		<div className="devices-filter-container grid grid-cols-3 sm:flex sm:justify-between gap-4 !px-0 !m-0">
			<div className="grid grid-cols-5 col-span-3 gap-2">
				<div
					style={{ height: '40px' }}
					className="col-span-3 input-search relative w-full"
				>
					<span className="search-icon">{searchIcon}</span>
					<input
						type="search"
						placeholder={devicesViewText('placeholder')}
						className="w-full text-[15px]"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
					/>
				</div>
				{isMobile && (
					<button
						onClick={() => {
							setOpen(true)
							setSelectedDevice(undefined)
						}}
						className="flex col-span-2 w-gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white text-nowrap p-2.5"
					>
						{plusIcon} <span>{devicesViewText('add_device')}</span>
					</button>
				)}
			</div>
			<div className="flex flex-col sm:flex-row gap-2 justify-end col-span-3">
				{!isMobile && (
					<button
						onClick={() => {
							setOpen(true)
							setSelectedDevice(undefined)
						}}
						className="flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white text-nowrap p-2.5"
					>
						{plusIcon} <span>{devicesViewText('add_device')}</span>
					</button>
				)}

				<SelectFilter
					onChange={onSelectFilter}
					selectedItem={selectedFilter?.label}
					toggleDropdown={toggleDropdown}
					dropdownOpen={dropdownOpen}
					className="w-full !justify-evenly !sm:justify-between !sm:w-fit"
					items={[
						{
							label: 'Filter by Brand',
							value: 'filter_by_brand',
						},
						...cameraBrands,
					]}
					showArrowDown={false}
				/>
			</div>
		</div>
	)
}

export default DeviceFilters
