import { MenuItem, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import order_history from "../../../../assets/svgs/order_history.svg";
import { RInputDeclare } from "../../../../components/custom/RInputDeclare";

const orderTransformer = {
    "new": { bgColor: "#f8dda7", textColor: "#94660c", readable: "New" },
    "new_order": { bgColor: "#f8dda7", textColor: "#94660c", readable: "New" },
    "on_hold": { bgColor: "#543c7f", textColor: "#543c7f", readable: "On Hold" },
    "processing": { bgColor: "#BCE8F1", textColor: "#31708F", readable: "Processing" },
    "order_cancelled": { bgColor: "#e5e5e5", textColor: "#777", readable: "Cancelled" },
    "order_completed": { bgColor: "#3C763D", textColor: "#3C763D", readable: "Completed" },
}

export function OrderGeneralBlock({ data, onUpdateOrderStatus }) {
    const [created, setCreated] = useState(new Date());
    const [status, setStatus] = useState('');
    const [customer, setCustomer] = useState("Guest");
    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        if (data?.status) {
            setStatus(data?.status)
            switch (data?.status) {
                case "new":
                    setStatusList([
                        { title: 'Processing', value: 'processing' },
                        { title: 'On Hold', value: 'on_hold' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                        { title: 'New', value: 'new' },
                    ])
                    break;
                case "on_hold":
                    setStatusList([
                        { title: 'Processing', value: 'processing' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                        { title: 'On Hold', value: 'on_hold' },
                    ])
                    break;
                case "processing":
                    setStatusList([
                        { title: 'Order Completed', value: 'order_completed' },
                        { title: 'On Hold', value: 'on_hold' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                        { title: 'Processing', value: 'processing' },

                    ])
                    break;
                case "order_cancelled":
                    setStatusList([
                        { title: 'Cancelled', value: 'order_cancelled' },

                    ])
                    break;
                case "order_completed":
                    setStatusList([
                        { title: 'Order Completed', value: 'order_completed' },
                    ])
                    break;
                default:
                    break;
            }
        } else {
            setStatus(null);
        }
    }, [data])
    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center mb-0">
                    <p className=' text-black1 text-lg font-medium'>General</p>
                </div>
                <RInputDeclare value={`Date Created`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    value={data?.order_summary?.created_at}
                    disabled={true}
                >
                </TextField>
                <RInputDeclare value={`Status`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    select
                    value={status}
                    onChange={event => {
                        setStatus(event.target.value);
                        onUpdateOrderStatus(event.target.value)
                    }}
                >
                    {statusList.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.title}
                        </MenuItem>
                    ))}
                </TextField>
                <RInputDeclare value={`Customer`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    value={data?.customer?.name}
                    disabled={true}
                    onChange={event => {
                        setCustomer(event.target.value)
                    }}
                >
                </TextField>

                <div className="flex flex-col mt-2 w-[100%]">
                    <RInputDeclare value={`Status changes`} className="!text-gray5 !mb-2 font-medium text-base" />
                    <div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
                        <div className="p-4 bg-[#F9F7F7] rounded-lg">
                            <img src={order_history} alt="desk" />
                        </div>
                        <div className="flex-1 flex flex-col gap-10">
                            <div className="flex flex-col justify-center align-center gap-1 order-history-container">
                                {data?.status_changes.map((item, index) => {
                                    const textColor = orderTransformer[item.new_status]?.textColor || 'text-black1 font-bold';
                                    return (
                                        <div className="flex flex-col pb-4">
                                            <div className={`${(index === 0 || (index === data?.status_changes?.length - 1)) ? 'order-history-item' : ''}`}></div>
                                            <span className={`text-sm font-bold`} style={{ color: textColor }}>{item?.readable_new_status}</span>
                                            <span className="text-gray5 text-base font-bold">{item.changed_at}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col border rounded-lg mt-4">
                    <div className="flex flex-row justify-between items-center border-b py-2 px-4">
                        <p className=" text-black1 text-lg font-medium">Shipping Information</p>
                    </div>
                    <TableBody className="w-full">
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Name
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.name ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Email
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.email ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Phone
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.phone ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Address
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.shipping_address?.address_line1 ?? '-'} {data?.shipping_address?.address_line2 ?? ''}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                State
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.shipping_address?.state ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                City
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.shipping_address?.city ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Postal code
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.shipping_address?.postcode ?? '-'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </div>



            </div>
            <div className="flex flex-col">
            </div>
        </div>
    );
}
