import {
	API_INVENTORY, API_INVENTORY_MANAGEMENT,
	API_SALES_METRICS
} from "../constants/API";
import apiFactory from "../utils/apiFactory";

const getInventories = async (query = '') => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}`,
		query: query,
		method: "GET",
	};
	return apiFactory(api);
};

const getInventory = async (id) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}/${id}`,
		method: "GET",
	};
	return apiFactory(api);
};

const storeInventory = async (payload) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}`,
		method: "POST",
	};
	return apiFactory(api, payload);
};

const updateInventory = async (id, payload) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}/${id}`,
		method: "PUT",
	};
	return apiFactory(api, payload);
};

const createUpdateInventoryAlert = async (payload) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}/alert`,
		method: "PUT",
	};
	return apiFactory(api, payload);
};

const getInventoryActivity = async (id) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}/${id}/activities`,
		method: "GET",
	};
	return apiFactory(api);
};

const getInventoryUsage = async (id) => {
	let api = {
		content_type: "application/json",
		url: `${API_INVENTORY}/${id}/inventory-usage-history`,
		method: "GET",
	};
	return apiFactory(api);
};

const deleteAlert = async (id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_INVENTORY}/alert/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api, payload)
}

const getInventorySummary = async (id) => {
	let api = {
		content_type: "application/json",
		url: `menu/${API_INVENTORY_MANAGEMENT}/summery`,
		method: "GET",
	};
	return apiFactory(api);
}

const getCrossLocationInventoryBalance = async (params) => {
	let api = {
		content_type: "application/json",
		url: `menu/${API_INVENTORY_MANAGEMENT}/cross-location-inventory-balance`,
		method: "GET",
	};
	return apiFactory(api, {}, params);
}

const getSalesMetrics = async (month, year) => {
	let api = {
		content_type: "application/json",
		url: `menu/${API_SALES_METRICS}/by-brands`,
		method: "GET",
	};
	let filter = {
		month: month,
		year: year
	}
	return apiFactory(api, {}, filter);
}


export default {
	getInventories, getInventory, storeInventory, updateInventory, getInventoryActivity, getInventoryUsage,
	createUpdateInventoryAlert, deleteAlert, getInventorySummary, getSalesMetrics, getCrossLocationInventoryBalance
}
