import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MSG_TYPE_TEXT } from '../../constants'
import ChatService from '../../services/chat-service'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import ChatList from './chat-list'
import ChatSection from './chat-section'

const Messages = () => {
	const { state } = useLocation()
	const { short_code } = useSelector((state) => state.app)
	const { user } = useSelector((state) => state.auth)
	const [selectedChannel, setSelectedChannel] = useState(null)
	const [message, setMessage] = useState(null)
	const [channels, setChannels] = useState([])
	const [loading, setLoading] = useState(false)
	const [conversation, setConversation] = useState([])

	useEffect(() => {
		if (state) {
			setSelectedChannel(state)
			getSingleChat(state.chat_id, true)
		}
	}, [state])

	const getChats = () => {
		ChatService.getChats()
			.then((res) => {
				setLoading(false)

				const mapData = res.data && res.data?.length > 0 ? res.data : []

				const chatsData = mapData?.map((chat) => ({
					id: chat?.id,
					endUserId: chat?.end_user_id,
					endUserName: chat?.end_user_name,
					venueUserId: chat?.venue_user_id,
					venueId: chat?.venue_id,
					status: chat?.status,
					createdAt: chat?.created_at,
					updatedAt: chat?.updated_at,
					lastMessageTime: chat?.last_message_time,
					lastMessageText: chat?.last_message_text,
					conversation: chat?.messages.map((message) => ({
						id: message?.id,
						text: message?.content,
						time: new Date(message?.created_at).toLocaleTimeString(),
						sentByMe: message.sender_id === chat.venue_user_id,
					})),
				}))

				setChannels(chatsData ?? [])
				identifyVenue(short_code)
				trackEvent('chat service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'all chats',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('chat service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'all chats',
				})
				setLoading(false)
			})
	}

	const getSingleChat = (id, isInitialLoad = false) => {
		if (isInitialLoad) {
			setLoading(true)
		}

		ChatService.getChatUser(id)
			.then((res) => {
				setLoading(false)
				setConversation(res ? res : [])
				identifyVenue(short_code)
				trackEvent('chat service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'single chat',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('chat service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'single chat',
				})
				setLoading(false)
			})
	}

	const handleSendMessage = (inputMsg) => {
		if (selectedChannel && inputMsg) { 
			let formData = new FormData();
			if (inputMsg.type == 'image' && inputMsg.content?.file != null) {
				formData.append('image', inputMsg.content?.file)
			}
			formData.append('receiver_id', conversation?.[0]?.sender_id ??
				selectedChannel?.end_user_id ??
				state?.end_user_id)
			formData.append('chat_id', conversation?.[0]?.chat_id ??
				selectedChannel?.chat_id ??
				state?.chat_id)
			formData.append('sender_id', conversation?.[0]?.receiver_id ??
				selectedChannel?.venue_user_id ??
				user?.id)
			formData.append('type', inputMsg.type)
			formData.append('content', inputMsg.type == MSG_TYPE_TEXT ? inputMsg.content : '')
			
			ChatService.createConversation(formData)
				.then((response) => {
					setLoading(false)
					setMessage(null)
					getSingleChat(selectedChannel.chat_id ?? conversation?.[0]?.chat_id)
					getChats()
				})
				.catch((error) => {
					setLoading(false)
					console.error('Error sending message:', error)
				})
		}
	}

	useEffect(() => {
		getChats()
	}, [])

	return (
		<div className="grid grid-cols-4 h-[50vh] p-6">
			<ChatList
				{...{
					channels,
					selectedChannel,
					setSelectedChannel,
					getSingleChat,
				}}
			/>
			<div className="col-span-3 h-[83vh]">
				{selectedChannel && (
					<ChatSection
						{...{
							handleSendMessage,
							message,
							channel:  selectedChannel,
							conversation,
							loading,
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Messages
