import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import {
	Box,
	CircularProgress,
	MenuItem,
	Modal,
	TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import AccommodationVacationRentalWhiteIcon from '../../assets/svgs/accommodation-vacation-white.svg'
import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker'
import { boxStyle } from '../../constants/types'
import TaskService from '../../services/housekeepingTaskService'
import { vbAssistantAskQuestion } from '../../store/actions/chatbot'
import { isEmpty } from '../../utils/common'
import { KEYS } from '../../utils/storage'
import AssistantButton from '../assistantButton'
import { RInputDeclare } from '../custom/RInputDeclare'

export const houseKeepingText = (text) =>
	t(`components.accommodation.housekeepingTaskModal.${text}`)

export default function HouseKeepingTaskModal({
	showModal,
	data,
	employees = [],
	rentalUnits = [],
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const formProps = useFormik({
		initialValues: {
			name: null,
			description: null,
			due_date: null,
			employee_id: null,
			rental_unit_id: null,
		},
		validationSchema: yup.object().shape({
			name: yup.string().trim().required('The title field is required.'),
			due_date: yup.date().required('The due date field is required.'),
			employee_id: yup.number().required('The employee field is required.'),
			rental_unit_id: yup
				.number()
				.required('The rental unit field is required.'),
		}),
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				formProps.setValues(data)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'housekeeping_task_details'

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		formProps.setFieldValue('description', generated)
		setAiQuery('')
		setGenerated('')
	}

	const onSubmit = (values) => {
		let payload = {
			...values,
			due_date: moment(values.due_date).format('YYYY-MM-DD HH:mm:ss'),
		}
		setLoading(true)
		;(data?.id == null
			? TaskService.createTask(payload)
			: TaskService.updateTask(payload, data?.id)
		)
			.then(({ data }) => {
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ??
						(data?.id == null
							? 'Task created successfully'
							: 'Task updated successfully')
				)
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				throw new Error(
					'An error occurred while creating a housekeeping task for accommodation module'
				)
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-010 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={AccommodationVacationRentalWhiteIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{data?.id != null
								? houseKeepingText('modalTitle.modifyTask')
								: houseKeepingText('modalTitle.assignTask')}
						</label>
					</div>
					<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
						{houseKeepingText('taskDetailDescription')}
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-4">
							<div className="flex flex-col">
								<RInputDeclare
									className={'!mb-2 !mt-2'}
									value={houseKeepingText('taskTitle')}
								/>
								<TextField
									required
									size="small"
									placeholder={houseKeepingText('taskTitlePlaceholder')}
									value={formProps.values.name}
									error={!isEmpty(formProps.errors.name)}
									helperText={formProps.errors.name}
									onChange={(e) =>
										formProps.setFieldValue('name', e.target.value)
									}
								/>
							</div>

							<div className="flex flex-col">
								<RInputDeclare
									className={'!mb-2 !mt-2'}
									value={houseKeepingText('taskDetail')}
								/>
								<TextField
									required
									size="small"
									value={formProps.values.description}
									placeholder={houseKeepingText('taskDetailPlaceholder')}
									multiline
									minRows={5}
									onChange={(e) =>
										formProps.setFieldValue('description', e.target.value)
									}
								/>
								<div className="flex flex-row justify-end mt-2">
									<AssistantButton
										onAction={() => handleOpenAIModal('description')}
									/>
								</div>
							</div>
							<div className="flex flex-col">
								<RInputDeclare
									className={'!mb-2 !mt-2'}
									value={houseKeepingText('dueDate')}
								/>
								<CustomDateTimePicker
									placeholder={houseKeepingText('dueDatePlaceholder')}
									classes="housekeeping_task_due_date"
									value={formProps.values.due_date}
									onChange={(v) => formProps.setFieldValue('due_date', v)}
								/>
								{!isEmpty(formProps.errors.due_date) && (
									<div className="text-[#d32f2f] text-[12px] mt-1 ml-2">
										{formProps.errors.due_date}
									</div>
								)}
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-4">
								<div className="flex flex-col">
									<RInputDeclare
										className={'!mb-2 !mt-2'}
										value={houseKeepingText('employee')}
									/>
									<TextField
										select
										value={formProps.values.employee_id}
										error={!isEmpty(formProps.errors.employee_id)}
										helperText={formProps.errors.employee_id}
										onChange={(e) =>
											formProps.setFieldValue('employee_id', e.target.value)
										}
										className="task-select"
									>
										{employees.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="flex flex-col">
									{venueType === 'hotel' && (
										<RInputDeclare
											className={'!mb-2 !mt-2'}
											value={houseKeepingText('unit')}
										/>
									)}
									{venueType === 'vacation_rental' && (
										<RInputDeclare
											className={'!mb-2 !mt-2'}
											value={houseKeepingText('rentalUnit')}
										/>
									)}
									<TextField
										select
										value={formProps.values.rental_unit_id}
										error={!isEmpty(formProps.errors.rental_unit_id)}
										helperText={formProps.errors.rental_unit_id}
										onChange={(e) =>
											formProps.setFieldValue('rental_unit_id', e.target.value)
										}
										className="task-select"
									>
										{rentalUnits.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{houseKeepingText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={formProps.handleSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : data?.id != null ? (
								houseKeepingText('update')
							) : (
								houseKeepingText('create')
							)}
						</button>
					</div>
				</Box>
			</Modal>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{houseKeepingText('modalTitle.aiAssistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									// placeholder={placeholder[aiField]}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{houseKeepingText('info.assistantInfo')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : handleGenerate}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												houseKeepingText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										// placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={handleSendReport}
											>
												{houseKeepingText('addToTaskDetails')}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
