import { API_MARKETING } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class QuizzService {
   static getQuizzes = async (query) => {
      let api = {
         content_type: 'application/json',
         url: `${API_MARKETING}/quizzes`,
         method: 'GET',
         query,
      }
      return apiFactory(api, {}, query)
   }

   static createQuizz = async (payload) => {
      let api = {
         content_type: 'application/json',
         url: `${API_MARKETING}/update-quiz-config`,
         method: 'PUT',
      }
      return apiFactory(api, payload)
   }
}
