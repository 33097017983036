import {
	Box,
	CircularProgress,
	FormControlLabel,
	Modal,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { boxStyle } from '../../constants/types'
import MenuService from '../../services/menuService'
import { RInputDeclare } from '../custom/RInputDeclare'

import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import creditIcon from '../../assets/svgs/credit-card.svg'
import { vbAssistantAskQuestion } from '../../store/actions/chatbot'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { KEYS } from '../../utils/storage'
import { categoryText } from '../../views/menu/categories'
import AssistantButton from '../assistantButton'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import GenericModal from '../generic-modal'

export default function CategoryModal({ showModal, data, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState([])
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [state, setState] = useState({
		available: 1,
	})
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				setCategories(res?.categories || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
			})
		if (showModal) {
			if (data?.id != null) {
				setState(data)
				setSelectedCategory(data?.parent?.id)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		let payload = {
			id: data?.id,
			title: state.title,
			description: state.description,
			visible: state.visible,
			parent_id: state.parent_id,
		}
		setLoading(true)
		MenuService.storeCategory(payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ?? categoryText('category_saved_successfully')
				)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
				setLoading(false)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || t('common.error_generic')
						)
					} else {
						NotificationManager.error(t('common.error_generic'))
					}
				}
			})
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'product_category_description'

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || t('common.error_generic')
					)
				} else {
					NotificationManager.error(t('common.error_generic'))
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		setState({ ...state, description: generated })
		setAiQuery('')
		setGenerated('')
	}

	const children = (
		<div className="flex flex-col pt-0 mb-4">
			<CustomFormInput
				value={state?.title}
				onChange={(e) => setState({ ...state, title: e.target.value })}
				placeholder={categoryText('title')}
				required={true}
			/>

			<RInputDeclare
				value={categoryText('description')}
				className=" !mb-2 !mt-6 !text-black1 font-medium text-base"
			/>
			<TextField
				required
				size="small"
				multiline
				minRows={3}
				value={state?.description}
				placeholder={categoryText('enter_description')}
				onChange={(e) => setState({ ...state, description: e.target.value })}
			/>
			<div className="flex flex-row justify-end mt-2">
				<AssistantButton
					onAction={() => handleOpenAIModal(categoryText('description'))}
				/>
			</div>

			{(venueType === 'sport_essentials' ||
				venueType === 'fashion_threads' ||
				venueType === 'pharmacy' ||
				venueType === 'home_decor') && (
					<CustomFormSelect
						onChange={(e) => setState({ ...state, parent_id: e.target.value })}
						placeholder={categoryText('parent_category_optional')}
						value={state?.parent_id ?? selectedCategory}
						options={categories}
						className="!h-10"
					/>
				)}

			<RInputDeclare
				value={categoryText('published')}
				className="!mb-1 !mt-0 !text-black1 font-medium text-base"
			/>
			<RadioGroup
				row
				className="gap-8"
				defaultValue={1}
				value={state?.visible}
				onChange={(e) => setState({ ...state, visible: e.target.value })}
			>
				<FormControlLabel
					value={1}
					control={<Radio />}
					label={categoryText('yes')}
				/>
				<FormControlLabel
					value={0}
					control={<Radio />}
					label={categoryText('no')}
				/>
			</RadioGroup>
		</div>
	)

	return (
		<div>
			<GenericModal
				{...{ onClose, children, onSubmit, loading }}
				open={visible}
				icon={creditIcon}
				saveText={
					data?.id != null ? categoryText('update') : categoryText('create')
				}
				title={categoryText(
					data?.id != null ? 'edit_category' : 'add_category'
				)}
			/>
			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{categoryText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{categoryText('vb_ai_assistant_info')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-tranparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': {
															color: '#009688',
														},
													}}
													size={16}
												/>
											) : (
												categoryText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{categoryText('add_to_ai_field')}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
