import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import GenericPagination from '../../../../components/generic-table/generic-pagination'
import GenericTable from '../../../../components/generic-table/generic-table'
import DetectionActivitiesService from '../../../../services/detectionActivities'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { plusIcon } from '../../../guests/guests/icons'
import EnableActivityModal from './EnableActivityModal'
import { venueColumns } from './data'
import { activitiesText } from './index'

const VenueDetectionActivities = () => {
	const { short_code } = useSelector((state) => state.app)

	const [open, setOpen] = useState(false)

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [venueActivities, setVenueActivities] = useState()
	const [paginatedData, setPaginatedData] = useState([])
	const [loading, setLoaded] = useState(false)

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage)
		setPage(0)
	}

	useEffect(() => {
		GetVenueActivities()
	}, [page, rowsPerPage])

	const GetVenueActivities = () => {
		let query = `&page=${page}&per_page=${rowsPerPage}`

		DetectionActivitiesService.GetVenueActivities(query)
			.then((res) => {
				setLoaded(true)
				setVenueActivities(res ?? [])
				setPaginatedData(res?.slice(0, rowsPerPage))
				identifyVenue(short_code)
				trackEvent('detection activities service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venue activities',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('detection activities service', {
					action_category: 'get',
					action_outcome: 'error',
					interaction_element: 'venue activities',
				})
			})
	}

	return (
		<Card className="!rounded-[12px]" elevation={0}>
			<CardContent className="!px-[25px] !py-[25px] !m-0 flex flex-col ">
				<div className="flex justify-between items-center gap-3 mb-[25px]">
					<div className="flex flex-col gap-1">
						<span className="text-xl font-bold text-[#09244B] ">
							{activitiesText('title_enable_by_you')}
						</span>
						<span className="text-[14px] text-gray10">
							{activitiesText('venue_activities_subtitle')}
						</span>
					</div>
					<button
						onClick={() => {
							setOpen(true)
						}}
						className="flex gap-2 col-span-2 w-gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white text-nowrap p-2.5"
					>
						{plusIcon} <span>{activitiesText('enable_activity')}</span>
					</button>
				</div>
				{venueActivities?.length && paginatedData?.length > 0 && (
					<>
						<GenericTable
							columns={venueColumns}
							data={venueActivities}
							alignRight={true}
							className="!px-[0px] !py-[0px] !pb-[25px] overflow-auto max-h-[300px] "
						/>

						<GenericPagination
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							count={paginatedData.length}
							{...{ page, setPage, rowsPerPage }}
						/>
					</>
				)}
			</CardContent>
			{open && (
				<EnableActivityModal
					open={open}
					onClose={() => setOpen(false)}
					GetVenueActivities={GetVenueActivities}
				/>
			)}
		</Card>
	)
}

export default VenueDetectionActivities
