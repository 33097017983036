import { API_WHITE_LABEL } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class WhiteLabelService {
	static get = async () => {
		let api = {
			content_type: 'application/json',
			url: API_WHITE_LABEL,
			method: 'GET',
		}
		return apiFactory(api)
	}

	static update = async (payload) => {
		let api = {
			content_type: 'application/json',
			url: API_WHITE_LABEL,
			method: 'PUT',
		}
		return apiFactory(api, payload)
	}
}
