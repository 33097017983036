import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../../assets/svgs/close.svg'
import IconButton from '../../../../components/common/IconButton'
import { RInputDeclare } from '../../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../../constants/types'
import useIsMobile from '../../../../helpers/is-mobile-hook'
import DetectionActivitiesService from '../../../../services/detectionActivities'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { parseLabel } from './AssignActivityModal'
import { activitiesText } from './index'
const { Option } = Select

export default function EnableActivityModal({
	onClose,
	open,
	GetVenueActivities,
}) {
	const { short_code } = useSelector((state) => state.app)
	const isMobile = useIsMobile()

	const initialValue = useMemo(() => {
		return {
			max_queue_time: '',
			alert_threshold: '',
			detection_activity_id: '',
		}
	}, [])

	const [config, setConfig] = useState(initialValue)

	const [loading, setLoading] = useState(false)

	const [options, setOptions] = useState([])
	const [loadingOptions, setLoadingOptions] = useState(false)
	const [selectedActivity, setSelectedActivity] = useState()

	const fetchOptions = () => {
		setLoadingOptions(true)
		let query = `&page=0&per_page=20`
		DetectionActivitiesService.GetGlobalActivities(query).then((res) => {
			const newOptions = res.map((item) => ({
				label: item.name,
				value: item.id,
				originalData: item,
			}))

			setOptions((prev) => [...prev, ...newOptions])
			setLoadingOptions(false)
		})
	}

	useEffect(() => {
		fetchOptions()
	}, [])

	const onSubmit = () => {
		setLoading(true)
		const { detection_activity_id, ...rest } = config
		const payload = { detection_activity_id, config: rest }
		DetectionActivitiesService.EnableActivity(payload)
			.then(() => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('create activity', {
					action_category: 'post',
					action_outcome: 'success',
					interaction_element: 'activitys',
				})
				NotificationManager.success(
					'Activity has been enabled successfully',
					'Success',
					3000
				)
				GetVenueActivities()
				onClose()
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(
					err?.errors?.email?.[0] ??
						'An error occurred while enabling the activity.',
					'Error',
					3000
				)
				identifyVenue(short_code)
				trackEvent('enable activity', {
					action_category: 'post',
					action_outcome: 'error',
					interaction_element: 'activitys',
				})
			})
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...boxStyle,
					maxWidth: 600,
					width: '100%',
					height: isMobile ? '100%' : 'unset',
					minHeight: '300px',
					borderRadius: isMobile ? 0 : '16px',
				}}
				className="outline-none px-024 pb-024 overflow-auto sm:max-h-[90vh] flex flex-col"
			>
				<div className="flex justify-between items-center">
					<IconButton
						px={false}
						// icon={activitysIcon}
						title={activitiesText('enable_activity')}
					/>
					<div className="pt-024 cursor-pointer" onClick={onClose}>
						<CloseIcon />
					</div>
				</div>
				<div className="flex flex-col gap-4 py-0 h-full">
					<div className="flex flex-col py-0">
						<RInputDeclare
							value={activitiesText('detection_activity')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select"
							value={config?.detection_activity_id}
							onChange={(value, option) => {
								setConfig({ ...config, detection_activity_id: value })
								setSelectedActivity(option?.originalData)
							}}
							options={options}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							suffixIcon={<ArrowDropDownIcon />}
						>
							{options.map((option) => (
								<Option key={option.value} value={option.value}>
									{option.label}
								</Option>
							))}
							{loadingOptions && <Option disabled>Loading...</Option>}
						</Select>
					</div>
					{selectedActivity &&
						selectedActivity?.default_config &&
						Object.entries(selectedActivity?.default_config).map(
							([key, value]) => (
								<div key={key} className="flex flex-col py-0">
									<label className="font-medium text-base text-black1 mb-2">
										{parseLabel(key)}
									</label>
									<TextField
										required
										size="small"
										type={typeof value}
										value={config?.[key]}
										placeholder={parseLabel(key)}
										onChange={(e) =>
											setConfig({ ...config, [key]: e.target.value })
										}
										margin="none"
										fullWidth
									/>
								</div>
							)
						)}
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{activitiesText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							activitiesText('enable_activity')
						)}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
