import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const formatBookingDates = (checkInDate, checkOutDate) => {
   const checkIn = new Date(checkInDate);
   const checkOut = new Date(checkOutDate);

   const optionsSameMonth = { month: 'short', day: 'numeric' };
   const optionsDifferentMonth = { month: 'short', day: 'numeric' };

   if (checkIn.getMonth() === checkOut.getMonth()) {
      return `${checkIn.toLocaleDateString('en-US', optionsSameMonth)}-${checkOut.getDate()}`;
   } else {
      return `${checkIn.toLocaleDateString('en-US', optionsSameMonth)} - ${checkOut.toLocaleDateString('en-US', optionsDifferentMonth)}`;
   }
};

const BookingCard = ({ booking, bookingStatus }) => {
   const navigate = useNavigate()
   const { short_code } = useSelector((state) => state.app)
   const handleNavigateToDetails = () => {
      navigate(`/${short_code}/admin/booking/booking/${booking?.id}`)
   }

   return (
      <div onClick={handleNavigateToDetails} className="border border-gray-200 rounded-[15px]  shadow-sm bg-white cursor-pointer" >
         <div className='p-4'>
            <div className='flex justify-between'>

               {bookingStatus}

            </div>
            <div className='mb-2'>
               <h3 className="text-[18px] !m-0 font-semibold">{booking?.guest?.name}</h3>
               <h3 className="text-[18px] !m-0 font-semibold">
                  {formatBookingDates(booking.check_in_date, booking.check_out_date)}
               </h3>
            </div>

            <p className="text-[16px] text-gray-500 font-semibold">{booking?.rental_unit?.name}</p>
         </div>
         <hr className='w-full border-[1px]' />
         <div className="text-center p-2">
            <span className='font-bold cursont-pointer'>Message</span>
         </div>
      </div>
   );
};

export default BookingCard;
