import { API_ACCOMMODATION } from "../constants/API";
import apiFactory from "../utils/apiFactory";

const GetRentalUnits = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const GetRentalUnit = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}`,
    method: "GET",
  };
  return apiFactory(api);
};

const DeleteRentalUnit = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}`,
    method: "DELETE",
  };
  return apiFactory(api);
};

const UpdateRentalUnitNameLocation = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/name-location`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const CreateRentalUnit = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const UpdateReantalUnitFacilities = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/facilities`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const UploadReantalUnitPhoto = async (payload, id) => {
  let api = {
    content_type: "multipart/form-data",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/upload-photo`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const UploadRentalUnitRoomPhoto = async (payload, rentalUnitId, roomId) => {
  let api = {
    content_type: "multipart/form-data",
    url: `${API_ACCOMMODATION}/rental-unit/${rentalUnitId}/upload-room-photo/${roomId}`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const UpdateReantalUnitPricingCalendar= async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/pricing-calendar`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const UpdateReantalUnitPoliciesAndRules= async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/policies-and-rules`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const UpdateUnitPricePerNight= async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/price-per-night`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const UpdateReantalUnitAccomodationSetup = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/accommodation-setup`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const AddReantalUnitAccomodationRoom = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/add-room`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const AdditionalFeeAndChargeCreate = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/additional-fee-charge-create`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const CustomRuleCreate = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/custom-rule`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const CustomRuleDelete = async (ruleId, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/delete-custom-rule/${ruleId}`,
    method: "DELETE",
  };
  return apiFactory(api, {});
};

const PricePerNightDelete = async (pricePerNightId, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/delete-price-per-night/${pricePerNightId}`,
    method: "DELETE",
  };
  return apiFactory(api, {});
};

const AdditionalFeeAndChargeUpdate = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/additional-fee-charge-update`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const DeleteReantalUnitAccomodationRoom = async (roomId, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-unit/${id}/delete-room/${roomId}`,
    method: "DELETE",
  };
  return apiFactory(api, {});
};

const GetThirdPartyBooking = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/third-party-booking/${id}/show`,
    method: "POST",
  };
  return apiFactory(api, payload);
};
const StoreThirdPartyBooking = async (payload, id) => {
  let api = {
    content_type: "multipart/form-data",
    url: `${API_ACCOMMODATION}/third-party-booking/${id}/store`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const GetConnectedCalendars = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-units/${id}/calendar-connections/`,
    method: "GET",
  };
  return apiFactory(api, {});
}

const CreateCalendarConnect = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-units/${id}/calendar-connections/`,
    method: "POST",
  };
  return apiFactory(api, payload);
}

const EditCalendarConnect = async (payload, id, connectionId) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-units/${id}/calendar-connections/${connectionId}`,
    method: "POST",
  };
  return apiFactory(api, payload);
}

const DisconnectCalendar = async (rentalUnitId, connectionId) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-units/${rentalUnitId}/calendar-connections/${connectionId}/disconnect`,
    method: "POST",
  };
  return apiFactory(api, {});
}

const RefreshConnection = async (rentalUnitId, connectionId) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/rental-units/${rentalUnitId}/calendar-connections/${connectionId}/refresh`,
    method: "POST",
  };
  return apiFactory(api, {});
}


const GetBooking = async (payload, filter) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/booking`,
    method: "GET",
  };
  return apiFactory(api, payload, filter);
};

const GetBookingById = async (id, payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/booking/${id}`,
    method: "GET",
  };
  return apiFactory(api, payload);
};

const ChangeBookingStatus = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/booking/change-status`,
    method: "PATCH",
  };
  return apiFactory(api, payload);
}

const ChangeBookingPaidStatus = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_ACCOMMODATION}/booking/paid`,
    method: "PATCH",
  };
  return apiFactory(api, payload);
}

export default {
  ChangeBookingPaidStatus,
  GetRentalUnit,
  GetRentalUnits,
  DeleteRentalUnit,
  CreateRentalUnit,
  UpdateRentalUnitNameLocation,
  UpdateReantalUnitFacilities,
  UploadReantalUnitPhoto,
  UpdateReantalUnitPricingCalendar,
  UpdateReantalUnitPoliciesAndRules,
  UpdateReantalUnitAccomodationSetup,
  AddReantalUnitAccomodationRoom,
  DeleteReantalUnitAccomodationRoom,
  AdditionalFeeAndChargeCreate,
  AdditionalFeeAndChargeUpdate,
  CustomRuleCreate,
  CustomRuleDelete,
  PricePerNightDelete,
  UploadRentalUnitRoomPhoto,
  GetThirdPartyBooking,
  StoreThirdPartyBooking,
  GetBooking,
  GetBookingById,
  UpdateUnitPricePerNight,
  GetConnectedCalendars,
  CreateCalendarConnect,
  DisconnectCalendar,
  RefreshConnection,
  EditCalendarConnect,
  ChangeBookingStatus
};
