import { useEffect, useState } from "react";

const useIsMobile = () => {
   const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
      if (window.innerWidth < 768) setIsMobile(true);
      else setIsMobile(false);
   }, [window.innerWidth]);

   return isMobile
}

export default useIsMobile;