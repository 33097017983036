import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Modal, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { boxStyle } from '../../../constants/types';

import { notification } from 'antd';
import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import Cookies from 'universal-cookie';
import GenericModal from '../../../components/generic-modal';
import MarketingService from '../../../services/marketingService';
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot';
import { KEYS } from '../../../utils/storage';
import CampaignsForm from './campaigns-form';

export const campaignsModalText = (text) =>
	t(`marketing.campaigns.CampaignModal.${text}`)

export default function CampaignModal({
	showModal,
	data,
	promotions = [],
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const [state, setState] = useState({
		available: 1,
	})

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const targetList = ['All guests']
	const retailTargetList = ['All customers']
	const guestJourney = ['Booking Confirmation', 'During Stay', 'Post Stay']
	// const targetList = ['Customers', 'Guests', 'Guests with no show', 'Guests with cancellation', 'Guests with x reservation']

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				const { promotions, ...rest } = data
				setState({
					...rest,
					promotion: data?.promotion ? `ID#${data.promotion.id}` : '',
				})
			}
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onChangeScheduled = (currentDate) => {
		setState({ ...state, scheduled_date: currentDate })
	}

	const onSubmit = () => {
		const { title, description, type, promotion, target } = state

		if (title && description && type && (promotion || target)) {

			if (state.id) {
				setLoading(true)
				MarketingService.UpdateCampaign({
					...state,
					promotion_id: state.promotion
						? parseInt(state.promotion.replace('ID#', ''))
						: null,
				})
					.then((res) => {
						onClose(true)
					})
					.catch((err) => {
						notification.open({
							message: <span className='pt-6'>{err?.response?.data?.error}</span>
							,
							icon: (
								<span style={{ fontSize: '20px' }}>
									<ExclamationCircleOutlined style={{ color: '#f74343' }} />
								</span>
							),
							style: {
								padding: "8px 12px",
								fontSize: '16px',
								display: "flex",
								alignItems: "center",
								justifyContent: "start"
							},
							placement: 'bottomRight',
						});
					}).finally(() => {
						setLoading(false)
					})
			} else {
				setLoading(true)
				MarketingService.CreateCampaign({
					...state,
					promotion_id: state.promotion
						? parseInt(state.promotion.replace('ID#', ''))
						: null,
				})
					.then((res) => {
						onClose(true)
						setLoading(false)
					})
					.catch(() => {
						NotificationManager.error('Failed', '')
						setLoading(false)
						throw new Error('Problem on CreateCampaign')
					})
			}
		}
	}

	const handleChangeType = (value) => {
		setState((v) => ({ ...v, type: value.target.value }))
	}

	const handleChangePromotion = (value) => {
		setState((v) => ({ ...v, promotion: value.target.value }))
	}

	const handleChangeTarget = (value) => {
		setState((v) => ({ ...v, target: value.target.value }))
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'campaign_description'
		if (aiField === 'Title') {
			askFor = 'campaign_title'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)

		if (aiField === 'Title') {
			setState({ ...state, title: generated })
		}

		if (aiField === 'Description') {
			setState({ ...state, description: generated })
		}
		setAiQuery('')
		setGenerated('')
	}

	const children = (
		<CampaignsForm
			{...{
				handleChangePromotion,
				handleChangeTarget,
				handleChangeType,
				handleOpenAIModal,
				onChangeScheduled,
				setState,
				state,
				venueType,
				promotions,
				retailTargetList,
				targetList,
			}}
		/>
	)

	const title =
		(data?.id != null
			? campaignsModalText('edit')
			: campaignsModalText('new')) +
		' ' +
		campaignsModalText('campaign')

	return (
		<div>
			<GenericModal
				{...{ onClose, onSubmit, children, title, loading }}
				open={visible}
				saveText={
					state.id ? campaignsModalText('update') : campaignsModalText('create')
				}
			/>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{campaignsModalText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{campaignsModalText('ai_assistant_info')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												campaignsModalText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{campaignsModalText('add_to')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
