import { Card, CardContent } from '@mui/material'
import React from 'react'
import { ResponsiveContainer } from 'recharts'
import CustomHeatmap from '../../../components/Charts/custom-headtmap'

const HeatMapCard = ({ title = '', subtitle, heatmapData = [], leftLabels, bottomLabels }) => {
   return (
      <Card elevation={0} className='!rounded-[12px] !p-[36px]'>
         <CardContent className="!p-0 !m-0 flex flex-col">
            <h3 className="text-lg font-bold">{title}</h3>
            <span className='text-[12px] text-gray10'>{subtitle}</span>
            <hr className='bg-[#C7C7C7] my-[15px] h-[1px] ' />
            <ResponsiveContainer width="100%" height={300} className='!p-0 my-0 pr-6 mt-4'>
               <CustomHeatmap data={heatmapData} height={300} leftLabels={leftLabels} bottomLabels={bottomLabels} />
            </ResponsiveContainer>
         </CardContent>
      </Card>
   )
}

export default HeatMapCard