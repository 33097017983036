import {
	Box,
	CircularProgress,
	Modal,
	TextField,
	TextareaAutosize,
	Tooltip,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Select } from 'antd'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { devicesViewText } from '.'
import devicesIcon from '../../../assets/icons/deviceImage.png'
import { ReactComponent as CloseIcon } from '../../../assets/svgs/close.svg'
import { ReactComponent as InfoIcon } from '../../../assets/svgs/info-icon.svg'
import CustomIconButton from '../../../components/common/IconButton'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'
import useIsMobile from '../../../helpers/is-mobile-hook'
import DeviceService from '../../../services/device-service'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { cameraBrands, locationOptions, spaceType, typeData } from './data'

export default function AddEditDeviceModal({
	selectedDevice,
	onClose,
	open,
	getDevices,
}) {
	const { short_code } = useSelector((state) => state.app)
	const isMobile = useIsMobile()

	const initialValue = useMemo(() => {
		return {
			device_nickname: '',
			type: '',
			brand: '',
			location: '',
			description: '',
			operating_system: '',
			type_of_space: '',
			camera_position: '',
			setup_status: 'active',
		}
	}, [])

	const [device, setDevice] = useState(initialValue)

	const {
		device_nickname,
		type,
		brand,
		location,
		description,
		tags,
		camera_position,
		setup_status,
	} = device

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (selectedDevice !== undefined) {
			setDevice({
				...selectedDevice,
				brand: cameraBrands.find((item) => item.label === selectedDevice?.brand)
					?.value,
			})
		} else {
			setDevice(initialValue)
		}
	}, [open, selectedDevice, initialValue])

	const payload = {
		device_nickname,
		type,
		brand,
		description,
		location,
		camera_position,
		tags,
		setup_status,
	}
	const onSubmit = () => {
		setLoading(true)

		if (selectedDevice !== undefined) {
			DeviceService.updateDevice(payload, selectedDevice.id)
				.then(() => {
					setLoading(false)
					getDevices()
					setDevice(initialValue)
					identifyVenue(short_code)
					trackEvent('update device', {
						action_category: 'put',
						action_outcome: 'success',
						interaction_element: 'devices',
					})
					NotificationManager.success(
						'Device has been updated successfully',
						'Success',
						3000
					)
					onClose()
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(
						err?.errors?.email?.[0] ??
							'An error occurred while updating the device.',
						'Error',
						3000
					)

					identifyVenue(short_code)
					trackEvent('update device', {
						action_category: 'put',
						action_outcome: 'error',
						interaction_element: 'devices',
					})
				})
		} else {
			DeviceService.createDevice(payload)
				.then(() => {
					setLoading(false)
					getDevices()
					setDevice(initialValue)
					identifyVenue(short_code)
					trackEvent('create device', {
						action_category: 'post',
						action_outcome: 'success',
						interaction_element: 'devices',
					})
					NotificationManager.success(
						'Device has been added successfully',
						'Success',
						3000
					)
					onClose()
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(
						err?.errors?.email?.[0] ??
							'An error occurred while adding the device.',
						'Error',
						3000
					)

					identifyVenue(short_code)
					trackEvent('create device', {
						action_category: 'post',
						action_outcome: 'error',
						interaction_element: 'devices',
					})
				})
		}
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...boxStyle,
					maxWidth: 600,
					width: '100%',
					height: isMobile ? '100%' : 'unset',
					borderRadius: isMobile ? 0 : '16px',
				}}
				className="outline-none px-024 pb-024 overflow-auto sm:max-h-[90vh]"
			>
				<div className="flex justify-between items-center">
					<CustomIconButton
						px={false}
						icon={devicesIcon}
						title={
							selectedDevice?.id != null
								? devicesViewText('edit_device')
								: devicesViewText('add_device')
						}
					/>
					<div className="pt-024">
						<CloseIcon onClick={onClose} />
					</div>
				</div>

				<div className="flex flex-col gap-4 py-0">
					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('device_nickname')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							size="small"
							value={device_nickname}
							placeholder={devicesViewText('enter_device_nickname')}
							onChange={(e) =>
								setDevice({ ...device, device_nickname: e.target.value })
							}
							margin="none"
							fullWidth
						/>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('type')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select"
							value={type}
							onChange={(value) => setDevice({ ...device, type: value })}
							options={typeData}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							suffixIcon={<ArrowDropDownIcon />}
						/>
					</div>

					{/* <div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('operating_system')}
							className="!my-0 !py-0 !text-black1 font-medium text-base"
						/>
						<CustomMUISelect
							width="w-full"
							placeholder="Select"
							data={typeData}
							value={operating_system}
							onChange={(e) =>
								setDevice({ ...device, operating_system: e.target.value })
							}
						/>
					</div> */}

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('location')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<div id="camera_position" className="w-full relative">
							<Select
								allowClear
								style={{ width: '100%', height: '40px' }}
								placeholder="Select"
								value={camera_position}
								onChange={(value, option) => {
									setDevice({
										...device,
										camera_position: option.value,
										location: option.label,
									})
								}}
								options={spaceType}
								getPopupContainer={(triggerNode) => triggerNode.parentNode}
								suffixIcon={<ArrowDropDownIcon />}
							/>
						</div>
						<div className="flex items-center gap-3 mt-2 text-[12px]">
							<Tooltip
								placement="top-start"
								arrow={true}
								sx={{ fontSize: '12px' }}
								title={
									<span style={{ fontSize: '12px' }}>
										{devicesViewText('type_of_space_help')}
									</span>
								}
							>
								<InfoIcon className="cursor-pointer" />
							</Tooltip>
							{devicesViewText('where_is_camera')}
						</div>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('camera_position')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<div id="camera_position" className="w-full relative">
							<Select
								mode="multiple"
								allowClear
								style={{ width: '100%' }}
								placeholder="Select"
								value={tags}
								onChange={(value) => setDevice({ ...device, tags: value })}
								options={locationOptions}
								getPopupContainer={(triggerNode) => triggerNode.parentNode}
								suffixIcon={<ArrowDropDownIcon />}
							/>
						</div>
						<div className="flex items-center gap-3 mt-2 text-[12px]">
							<Tooltip
								placement="top-start"
								arrow={true}
								title={
									<span style={{ fontSize: '12px' }}>
										{devicesViewText('define_camera_position_help')}
									</span>
								}
								sx={{ fontSize: '12px' }}
							>
								<InfoIcon className="cursor-pointer" />
							</Tooltip>
							{devicesViewText('define_camera_position')}
						</div>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('brand')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<Select
							allowClear
							style={{ width: '100%', height: '40px' }}
							placeholder="Select"
							value={brand}
							onChange={(value) => setDevice({ ...device, brand: value })}
							options={cameraBrands}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							suffixIcon={<ArrowDropDownIcon />}
						/>
					</div>

					{/* <div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('location')}
							optional={true}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<TextField
							size="small"
							required
							value={location}
							placeholder={devicesViewText('description_optional')}
							onChange={(e) =>
								setDevice({ ...device, location: e.target.value })
							}
							margin="none"
							fullWidth
						/>
					</div> */}

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('description')}
							optional={true}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<TextareaAutosize
							name="description"
							minRows={3}
							className="border outline-none p-2"
							value={description}
							placeholder="Enter description"
							onChange={(e) =>
								setDevice({ ...device, description: e.target.value })
							}
						/>
					</div>
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{devicesViewText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							devicesViewText('add_device')
						)}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
