import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'

import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import locationIcon from '../../../assets/svgs/location.svg'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import GenericModal from '../../../components/generic-modal'
import { isAccommodation } from '../../../helpers/venueHelpers'
import AccommodationService from '../../../services/accommodationService'
import MarketingService from '../../../services/marketingService'
import MenuService from '../../../services/menuService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import { optionsArray } from '../../ecomm-platform/common'

export const discountModalText = (text) =>
	t(`marketing.promotions.DiscountModal.${text}`)

export default function DiscountModal({ showModal, data, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [products, setProducts] = useState([])
	const [productNames, setProductNames] = useState([])
	const [categories, setCategories] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [rentalUnits, setRentalUnits] = useState([])
	const [state, setState] = useState({})

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState({
					...data,
					type: data.type?.charAt(0).toUpperCase() + data.type?.slice(1) // Capitalize first letter
				})
				setProductNames(data?.product_names)
			}
		}
		setVisible(showModal)
		loadProducts()
		loadCategories()
		loadRentalUnit()
	}, [showModal])

	const loadProducts = () => {
		MenuService.getProducts()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setProducts(res?.products || [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'products',
				})
			})
	}

	const loadCategories = () => {
		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				setCategories(res?.categories || [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
			})
	}

	const loadRentalUnit = () => {
		AccommodationService.GetRentalUnits()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'rental ',
				})
				setRentalUnits(res?.data || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'rental',
				})
			})
	}

	const onRemoveProduct = (v) => {
		let tmp = selectedProducts.slice(0)
		let index = tmp.findIndex((t) => t.id === v.id)
		if (index !== -1) {
			tmp.splice(index, 1)
		}
		setSelectedProducts(tmp)
	}

	const handleChangeType = (value) => {
		setState((v) => ({ ...v, type: value.target.value }))
	}

	const handleChangeAppliedFor = (value, index) => {
		setState((v) => ({ ...v, apply_for: value }))
	}

	const handleChangeStart = (value) => {
		setState((v) => ({ ...v, start_time: value }))
	}

	const handleChangeEnd = (value) => {
		setState((v) => ({ ...v, end_time: value }))
	}

	const onSubmit = () => {
		const { type, value, start_time, end_time } = state

		if (!isAccommodation(venueType)) {
			if (!type || !value || !start_time || !end_time) {
				return
			}
		} else {
			if (!type || !value || !start_time || !end_time || !state.apply_for) {
				return
			}
		}

		if (state.id) {
			MarketingService.UpdateDiscount({
				...state,
				type: state.type.toLowerCase(),
				product_ids: selectedProducts.map((p) => ({ id: p.id })),
				rental_unit_id: state.rental_unit?.id,
				category_id: state.category?.id,
			})
				.then((res) => {
					onClose(true)
					NotificationManager.success(
						data?.message ?? 'Discount is updated successfully'
					)
				})
				.catch((err) => {
					NotificationManager.error('Failed', '')
				})
		} else {
			MarketingService.CreateDiscount({
				...state,
				type: state.type.toLowerCase(),
				product_ids: selectedProducts.map((p) => ({ id: p.id })),
				rental_unit_id: state.rental_unit?.id,
				category_id: state.category?.id,
			})
				.then((res) => {
					onClose(true)
				})
				.catch((err) => {
					NotificationManager.error('Failed', '')
				})
		}
	}

	const children = (
		<div className="flex flex-col overflow-auto max-h-[60vh]">
			<CustomFormSelect
				title={discountModalText('type')}
				onChange={handleChangeType}
				value={state?.type || data?.type}
				placeholder={discountModalText('choose_type')}
				options={optionsArray(['Fixed', 'Percentage'])}
			/>

			<RInputDeclare
				value={discountModalText('value')}
				className="!mt-6 !mb-2 !text-black1 font-medium"
			/>
			<div className="flex flex-row items-center border rounded-md h-10">
				<span className="flex items-center border border-r border-0 px-4 h-10">
					{state?.type
						? state?.type?.toLowerCase() === 'fixed'
							? ''
							: '%'
						: ''}
				</span>
				<input
					type="number"
					className="border-0 outline-0 flex-1 px-4"
					value={state?.value}
					onChange={(e) =>
						setState((v) => ({ ...v, value: parseInt(e.target.value) }))
					}
				/>
			</div>

			{!(
				venueType === 'sport_essentials' ||
				venueType === 'home_decor' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads'
			) &&
				!isAccommodation(venueType) && (
					<>
						<RInputDeclare
							value={discountModalText('reservation_count')}
							className="!mt-6 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							type="number"
							value={state?.reservation_count}
							placeholder={discountModalText('enter_reservation_count')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({
									...state,
									reservation_count: parseInt(e.target.value),
								})
							}
						/>
					</>
				)}

			{isAccommodation(venueType) && (
				<>
					<RInputDeclare
						value={discountModalText('applied_for')}
						className="!mt-6 !mb-2 !text-black1 font-medium"
					/>
					<CustomSelect
						values={['Per night']}
						value={state?.apply_for}
						placeholder={discountModalText('choose_option')}
						height={'!h-10'}
						className={'!h-10 border !border-gray6'}
						handleChange={handleChangeAppliedFor}
					/>

					<>
						<RInputDeclare
							value={discountModalText('rental_unit')}
							className="!mt-6 !mb-2 !text-black1 font-medium"
						/>
						<CustomSelect
							height={'!h-10'}
							className={'!h-10 border !border-gray6'}
							values={rentalUnits}
							value={state?.rental_unit?.name}
							placeholder={discountModalText('select_rental_unit')}
							renderRow={(item) => <p>{item.name}</p>}
							handleChange={(v) => {
								setState({ ...state, rental_unit: v })
							}}
						/>
					</>
				</>
			)}

			{(venueType === 'sport_essentials' ||
				venueType === 'home_decor' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads') && (
				<>
					<RInputDeclare
						value={discountModalText('category')}
						className="!mt-6 !mb-2 !text-black1 font-medium"
					/>
					<CustomSelect
						height={'!h-10'}
						className={'!h-10 border !border-gray6'}
						values={categories}
						value={state?.category?.title}
						placeholder={discountModalText('select_category')}
						renderRow={(item) => <p>{item.title}</p>}
						handleChange={(v) => {
							setState({ ...state, category: v })
						}}
					/>

					<RInputDeclare
						value={discountModalText('products')}
						className="!mt-6 !mb-2 !text-black1 font-medium"
					/>
					<CustomSelect
						height={'!h-10'}
						className={'!h-10 border !border-gray6'}
						values={products}
						value={
							selectedProducts.length > 0
								? selectedProducts
								: products.filter(
										(item) =>
											productNames?.findIndex((a) => a == item.title) != -1
									)
							}
							isMultiple={true}
							placeholder={discountModalText('select_products')}
							renderRow={(item) => <p>{item.title}</p>}
							renderValue={(item) => <span>{item?.title}</span>}
							handleChange={(v) => {
								let tmp = selectedProducts.slice(0)
								let index = tmp.findIndex((t) => t.id == v.id)
								if (index == -1) {
									tmp.push({ ...v, quantity: 1 })
								}
								setSelectedProducts(tmp)
							}}
							onRemoveItem={onRemoveProduct}
						/>
					</>
				)}

			<RInputDeclare
				value={discountModalText('start_time')}
				className="!mt-6 !mb-0 !text-black1 font-medium"
			/>
			<CustomDateTimePicker
				placeholder={discountModalText('start_time')}
				value={state?.start_time}
				onChange={handleChangeStart}
			/>

			<RInputDeclare
				value={discountModalText('end_time')}
				className="!mt-6 !mb-0 !text-black1 font-medium"
			/>
			<CustomDateTimePicker
				placeholder={discountModalText('end_time')}
				value={state?.end_time}
				onChange={handleChangeEnd}
			/>
		</div>
	)

	const title =
		(data?.id != null ? discountModalText('edit') : discountModalText('new')) +
		' ' +
		discountModalText('discount')

	return (
		<GenericModal
			{...{ onClose, onSubmit, loading, children, title }}
			saveText={
				state.id ? discountModalText('update') : discountModalText('create')
			}
			icon={locationIcon}
			open={visible}
		/>
	)
}
