import { t } from 'i18next'
import React from 'react'
import DynamicBreadcrumbs from '../../components/shared/dynamicBreadCrumbs'
import { addBreadcrumbItems } from './common'
import NewSynchronization from './new-sync'

export const syncText = (text) => t(`sync.${text}`)

const AllSyncs = () => {
	const syncs = [
		{
			title: 'Sync Skus',
			type: 'sku',
			modalTitle: syncText('sku_modal_title'),
			modalSubTitle: syncText('sku_modal_sub_title'),
		},
		{
			title: 'Sync Prices',
			type: 'price',
			modalTitle: syncText('price_modal_title'),
			modalSubTitle: syncText('price_modal_sub_title'),
		},
		{
			title: 'Sync Stock',
			type: 'stocks',
			modalTitle: syncText('stocks_modal_title'),
			modalSubTitle: syncText('stocks_modal_sub_title'),
		},
		{
			title: 'Calculate stock',
			type: 'calculate-stock',
			modalTitle: syncText('calcualte_stock_modal_title'),
			modalSubTitle: syncText('calcualte_stock_modal_sub_title'),
		},
		// {
		// 	title: 'Sync Sales',
		// },
		// {
		// 	title: 'Sync Products',
		// 	type: 'product',
		// },
	]

	return (
		<div className="flex flex-col gap-5 h-full px-6">
			<DynamicBreadcrumbs breadcrumbItems={addBreadcrumbItems} />

			<div className="grid grid-cols-2 gap-6">
				{syncs.map((item, index) => (
					<NewSynchronization
						title={item.title}
						index={index}
						type={item.type}
						modalTitle={item?.modalTitle}
						modalSubTitle={item?.modalSubTitle}
					/>
				))}
			</div>
		</div>
	)
}

export default AllSyncs
