import { Card, CardContent, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import * as yup from 'yup'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'
import QuizzService from '../../../services/quizzes-service'
import { columns } from './data'

export const quizText = (text) => t(`marketing.quiz.${text}`)

export default function Quizzes() {
   const [page, setPage] = useState(0)

   const [rowsPerPage, setRowsPerPage] = useState(10)

   const [totalCount, setTotalCount] = useState(0)

   const [loaded, setLoaded] = useState(true)
   const [selectedQuiz, setSelectedQuiz] = useState()
   const [quizzes, setQuizzes] = useState([])
   const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] = useState(false)
   const [loading, setLoading] = useState(false)

   const formik = useFormik({
      initialValues: {
         word_count: '',
         max_earn: '',
         earn_per_correct_answer: ''
      },
      validationSchema: yup.object().shape({
         word_count: yup.number()
            .required(quizText('validation.wordCountRequired'))
            .positive('Word count must be a positive number')
            .integer('Word count must be an integer'),
         max_earn: yup.number()
            .required(quizText('validation.maxEarnRequired'))
            .positive('Max earn must be a positive number'),
         earn_per_correct_answer: yup.number()
            .required(quizText('validation.earnPerCorrectAnswerRequired'))
            .positive('Earn per correct answer must be a positive number')
      }),
      onSubmit: (values) => handleSaveQuiz(values),
      validateOnChange: false,
      validateOnBlur: true
   })

   const { setFieldValue, resetForm } = formik;

   useEffect(() => {
      getAllQuizzes();
   }, [page, rowsPerPage])

   const getAllQuizzes = () => {
      let payload = {
         page: page + 1,
         per_page: rowsPerPage
      }
      QuizzService
         .getQuizzes(payload)
         .then((res) => {
            setQuizzes(res?.quizzes.data ?? []);
            setTotalCount(res.quizzes.total);
            setFieldValue('word_count', res?.configuration?.wordcount ?? '');
            setFieldValue('max_earn', res?.configuration?.max_earn ?? '');
            setFieldValue('earn_per_correct_answer', res?.configuration?.earn_per_correct_answer ?? '');
         })
         .catch(() => {
            setLoaded(true)
         }).finally(() => {
            setLoading(false)
         })
   }

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event)
      setPage(0)
   }

   const handleSaveQuiz = (values) => {
      const { word_count, max_earn, earn_per_correct_answer } = values

      // Validate inputs
      if (!word_count || !max_earn || !earn_per_correct_answer) {
         NotificationManager.error(
            'Please fill in all fields',
            'Error',
            3000
         )
         return;
      }

      const payload = {
         wordcount: Number(word_count),
         max_earn: Number(max_earn),
         earn_per_correct_answer: Number(earn_per_correct_answer)
      }

      QuizzService
         .createQuizz(payload)
         .then(() => {
            NotificationManager.success(
               'Quiz has been created successfully',
               'Success',
               3000
            )
            // Refresh quizzes list
            getAllQuizzes();
         })
         .catch((error) => {
            NotificationManager.error(
               error.message || t('common.error_generic'),
               'Error',
               3000
            )
         })
   }

   const onDelete = (id) => {
      setSelectedQuiz(id)
      setAreYouSureDeleteModalOpen(true)
   }

   const handleDeleteQuiz = (values) => {
      // quizzesService
      //    .deleteQuiz({ quiz_id: selectedQuiz })
      //    .then(() => {
      //       NotificationManager.success(
      //          'Quiz has been deleted successfully',
      //          'Success',
      //          3000
      //       )
      //       setAreYouSureDeleteModalOpen(false)
      //       getAllQuizzes()
      //    })
      //    .catch((error) => {
      //       NotificationManager.error(
      //          error.message || t('common.error_generic'),
      //          'Error',
      //          3000
      //       )
      //    })
   }

   return (
      <div>

            <div className="flex flex-col h-full p-6">
               <Card className="mb-6">
                  <CardContent className="!px-6 py-4">
                     <div className="flex flex-col mb-2">
                        <span className="text-black1 text-xl font-medium">
                           Quiz Reward Settings
                        </span>
                        <span className="text-black1 text-m font-medium">Configure point earnings and completion requirements for all quizzes</span>
                     </div>
                     <form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                           {/* Word Count Field */}
                           <div className="flex flex-col">
                              <RInputDeclare
                                 className={'!mb-2 !mt-2'}
                                 value={quizText('fields.wordCount')}
                              />
                              <TextField
                                 required
                                 size="small"
                                 type="number"
                                 placeholder={quizText('placeholders.wordCountPlaceholder')}
                                 value={formik.values.word_count}
                                 error={!!formik.errors.word_count}
                                 helperText={formik.errors.word_count}
                                 onChange={(e) => formik.setFieldValue('word_count', e.target.value)}
                              />
                           </div>

                           {/* Max Earn Field */}
                           <div className="flex flex-col">
                              <RInputDeclare
                                 className={'!mb-2 !mt-2'}
                                 value={quizText('fields.maxEarn')}
                              />
                              <TextField
                                 required
                                 size="small"
                                 type="number"
                                 placeholder={quizText('placeholders.maxEarnPlaceholder')}
                                 value={formik.values.max_earn}
                                 error={!!formik.errors.max_earn}
                                 helperText={formik.errors.max_earn}
                                 onChange={(e) => formik.setFieldValue('max_earn', e.target.value)}
                              />
                           </div>

                           {/* Earn Per Correct Answer Field */}
                           <div className="flex flex-col">
                              <RInputDeclare
                                 className={'!mb-2 !mt-2'}
                                 value={quizText('fields.earnPerCorrectAnswer')}
                              />
                              <TextField
                                 required
                                 size="small"
                                 type="number"
                                 placeholder={quizText('placeholders.earnPerCorrectAnswerPlaceholder')}
                                 value={formik.values.earn_per_correct_answer}
                                 error={!!formik.errors.earn_per_correct_answer}
                                 helperText={formik.errors.earn_per_correct_answer}
                                 onChange={(e) => formik.setFieldValue('earn_per_correct_answer', e.target.value)}
                              />
                           </div>
                        </div>
                        <div className="mt-4 flex justify-end">
                           <button type='submit' className="flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5">
                              {/* <img src={plusIcon} alt="plus" /> */}
                              <span>Save Configuration</span>
                           </button>
                        </div>
                     </form>
                  </CardContent>
               </Card>

               <Card>
                  <CardContent className="!p-0 !m-0 flex flex-col">
                     <div className="flex flex-col py-2 px-6">
                        <div className="flex items-center gap-4">
                           <span className="text-black1 text-xl font-medium">
                              {quizText('quizPerformanceAnalytics')}
                           </span>
                           <span className="bg-blue2 text-black1 px-2 rounded-xl font-medium">
                              {totalCount || 0} {quizText('quizzes')}
                           </span>
                        </div>
                        <span className="text-black1 text-m font-medium">Here, you can manage Track completion rates, user registrations, and rewards earned across all quizzes.</span>
                     </div>

                  {quizzes?.length > 0 ? (
                        <>
                           <GenericTable
                              columns={columns}
                              data={quizzes}
                              {...{ onDelete }}
                              className="!pt-2 overflow-x-auto"
                           />

                           <GenericPagination
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              count={totalCount}
                              {...{ page, setPage, rowsPerPage }}
                           />
                        </>
                     ) : (
                        <div className="border border-x-0 border-b-0 mt-5 mb-10">
                           <TableEmptyData content={'quizzes'} />
                        </div>
                     )}

                  </CardContent>
               </Card>
         </div>
         {isAreYouSureDeleteModalOpen && (
            <AreYouSureModal
               title={quizText('delete_quiz')}
               content={quizText('delete_quiz_confirmation')}
               firstButton={quizText('no_go_back')}
               secondButton={quizText('yes_i_am')}
               showModal={isAreYouSureDeleteModalOpen}
               onClose={() => setAreYouSureDeleteModalOpen(false)}
               onSubmit={handleDeleteQuiz}
            />
         )}
      </div>
   )
}