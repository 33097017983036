import { ContentCopyOutlined } from '@mui/icons-material'
import {
	Card,
	CardContent,
	Checkbox,
	ImageList,
	ImageListItem,
	TextField
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import CustomAutocompleteWithAdd from '../../../components/custom/CustomAutocompleteWithAdd/CustomAutocompleteWithAdd'
import CustomSelect from '../../../components/custom/CustomSelect'
import CustomTimePicker from '../../../components/custom/CustomTimePicker/CustomTimePicker'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import HotelEmptyData from '../../../components/hotel/hotelEmptyData'
import { isFB } from '../../../helpers/venueHelpers'
import VenueService from '../../../services/venuService'
import { isEmpty } from '../../../utils/common'
import { KEYS } from '../../../utils/storage'
import BrandCustomize from './brandCustomize'

const getTranslation = (text) => t(`account.venueWebProfile.view.${text}`)

// import './index.css';
const paymentOptions = [
	{ id: 1, name: 'Visa' },
	{ id: 2, name: 'MasterCard' },
	{ id: 3, name: 'Cash on Delivery' },
	{ id: 4, name: 'Scan to pay' },
]

const VenueWebProfileScreen = () => {
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const validator = yup.object().shape({
		neighborhood:
			isFB(venueType) || venueType === 'vacation_rental'
				? yup
						.string()
						.trim()
						.required(getTranslation('validation.neighborhoodRequired'))
						.max(72, getTranslation('validation.maxExceeded'))
						.min(2, getTranslation('validation.minNotMet'))
				: yup.string(),
		dining_style: isFB(venueType)
			? yup
					.string()
					.trim()
					.required(getTranslation('validation.diningStyleRequired'))
					.max(72, getTranslation('validation.maxExceeded'))
					.min(2, getTranslation('validation.minNotMet'))
			: yup.string(),
		dress_code: isFB(venueType)
			? yup
					.string()
					.trim()
					.required(getTranslation('validation.dressCodeRequired'))
					.max(72, getTranslation('validation.maxExceeded'))
					.min(2, getTranslation('validation.minNotMet'))
			: yup.string(),
		parking_details: yup
			.string()
			.trim()
			.required(getTranslation('validation.parkingDetailsRequired'))
			.max(72, getTranslation('validation.maxExceeded'))
			.min(2, getTranslation('validation.minNotMet')),
		description: yup
			.string()
			.trim()
			.required(getTranslation('validation.descriptionRequired'))
			.max(500, getTranslation('validation.maxExceeded'))
			.min(15, getTranslation('validation.minNotMet')),
		additional: yup
			.string()
			.trim()
			.required(getTranslation('validation.additionalRequired'))
			.max(250, getTranslation('validation.maxExceeded'))
			.min(15, getTranslation('validation.minNotMet')),
		payment_options:
			isFB(venueType) || venueType === 'vacation_rental'
				? yup
						.array()
						.required(getTranslation('validation.paymentTypeRequired'))
						.min(1)
				: yup.array().notRequired(),
		tags:
			isFB(venueType) || venueType === 'vacation_rental'
				? yup.array().required(getTranslation('validation.tagsRequired')).min(1)
				: yup.array().notRequired(),
		benefit_title: yup.string().notRequired(),
		benefits: yup.array().notRequired(),
		stars:
			venueType === 'hotel' || venueType === 'vacation_rental'
				? yup
						.string()
						.trim()
						.required(getTranslation('validation.starsRequired'))
				: yup.string(),
		hotel_type:
			venueType === 'hotel' || venueType === 'vacation_rental'
				? yup
						.string()
						.trim()
						.required(getTranslation('validation.rentalTypeRequired'))
				: yup.string(),
		has_free_wifi: yup.boolean(),
		has_free_breakfast: yup.boolean(),
		has_spa: yup.boolean(),
		has_events_hall: yup.boolean(),
		has_gym: yup.boolean(),
		has_restaurant: yup.boolean(),
		room_service: yup.date(),
		wifi: yup.string(),
		min_money_value: yup.number().integer(),
		max_money_value: yup.number().integer(),
	})

	const [configured, setConfigured] = useState(false)
	const [loading, setLoading] = useState(true)
	const [whiteLabelUrl, setWhiteLabelUrl] = useState('')

	const [tags, setTags] = useState([])
	const [benefits, setBenefits] = useState([])
	const [images, setImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	])
	const formikProps = useFormik({
		initialValues: {
			neighborhood: '',
			dining_style: '',
			dress_code: '',
			parking_details: '',
			delivery_fee: '',
			description: '',
			additional: '',
			stars: '',
			hotel_type: '',
			has_free_wifi: false,
			has_free_breakfast: false,
			has_spa: false,
			has_events_hall: false,
			has_gym: false,
			has_restaurant: false,
			allow_reservation_from: false,
			room_service: new Date(),
			wifi: '',
			min_money_value: 0,
			max_money_value: 0,
			tags: [],
			benefit_title: '',
			benefits: [],
			payment_options: [],
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	useEffect(() => {
		getDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Empty dependency array to run only once after initial render

	const getDetails = () => {
		VenueService.CheckWebProfile()
			.then((res) => {
				setLoading(false)
				setConfigured(true)
				const information = res.whiteLabelInformation.information

				// Check if the object is not null and not empty
				if (information && Object.keys(information)?.length > 0) {
					formikProps.setValues(information)
				}
				setTags(res.whiteLabelInformation.information.tags)
				setBenefits(res.whiteLabelInformation.information.benefits)
				setWhiteLabelUrl(res.whiteLabelInformation?.url)
				if (res.whiteLabelInformation.gallery?.length > 0) {
					const tempImages = [...images]
					res.whiteLabelInformation.gallery.forEach((item, index) => {
						tempImages[index] = item
					})
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				throw new Error('Problem on CheckWebProfile')
			})
	}

	const onSubmit = (values) => {
		window.scrollTo(0, 0)

		const payload = {
			data: { ...values },
		}

		setLoading(true)
		VenueService.UpdateWebProfileInformation(payload)
			.then((res) => {
				NotificationManager.success(
					'Venue web space information has been saved successfully'
				)
				setLoading(false)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				setLoading(false)
				throw new Error('Problem on UpdateWebProfileInformation')
			})
	}
	const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'gallery')
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}
		setLoading(true)
		VenueService.uploadPhoto(formData)
			.then((res) => {
				// hope response has the new image, or at least id
				getDetails()
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				throw new Error('Problem uploading image')
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
				throw new Error('Unable do delete Photo')
			})
	}
	const onCopyText = ({ url, label }) => {
		if (url) {
			navigator.clipboard.writeText(url)
			NotificationManager.success(
				`${label} is copied successfully and can be shared everywhere`
			)
		}
	}
	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div
			className="h-full p-6 overflow-y-auto"
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<div className="flex flex-row justify-between items-center w-full p-2">
					<p
						style={{ padding: '10px 15px 10px 15px' }}
						className="text-black1 font-medium text-[20px]"
					>
						{getTranslation('customizableBrandProfile')}
					</p>
					{whiteLabelUrl ? (
						<div className="flex flex-row justify-start items-center px-2">
							<div className="!text-black1 font-medium text-base">
								<a
									className="text-black1 text-base"
									href={whiteLabelUrl}
									target="_blank"
									rel="noopener noreferrer"
								>
									{whiteLabelUrl}
								</a>
							</div>
							<div
								className="ml-4 cursor-pointer"
								onClick={() =>
									onCopyText({ url: whiteLabelUrl, label: 'Link' })
								}
							>
								<ContentCopyOutlined />
							</div>
						</div>
					) : null}
				</div>
				<div className="justify-between relative">
					<CardContent style={{ background: '#f6f6f8' }}>
						<div className="relative started-form">
							<p>{getTranslation('completeYourCustomizableBrandProfile')}</p>
							{getTranslation('maxPotential')}
						</div>
					</CardContent>
				</div>
			</Card>

			{configured ? (
				<>
					<Card className="mt-6">
						<BrandCustomize />
					</Card>
					<Card className="mt-6">
						<p
							style={{ padding: '10px 15px 10px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{getTranslation('gallery')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="relative started-form">
								<div className="flex-col mt-0 rounded-3xl gap-3 md:gap-6">
									<div className="flex flex-col h-full mt-0 py-4">
										<ImageList variant="quilted" cols={6}>
											{images.map((item, index) => (
												<ImageListItem
													key={'img_uploader' + index}
													className="pl-2 pb-2"
												>
													<ImgDragUpload
														backgroundColor={'#fff'}
														image={item ? { url: item?.photo_path } : null}
														onCancel={() => onCancelImage(index)}
														onChange={(img) => onUploadImage(img, index)}
														label={
															'Click to upload or drag and drop, PNG, JPG or JPEG (max. 12MB)'
														}
														classes="max-h-[180px] p-2 mb-2 mt-2"
													/>
												</ImageListItem>
											))}
										</ImageList>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
					<Card className="mt-6">
						<p
							style={{ padding: '20px 15px 20px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{getTranslation('informationTitle')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="flex flex-col">
								<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
									{isFB(venueType) || venueType === 'vacation_rental' ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('neighborhoodLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.neighborhood}
												error={formikProps.errors.neighborhood}
												placeholder={getTranslation('neighborhoodLabel')}
												style={{ background: '#fff' }}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												onChange={(e) => {
													formikProps.setFieldValue(
														'neighborhood',
														e.target.value
													)
												}}
											/>
											{!isEmpty(formikProps.errors.neighborhood) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.neighborhood}
												</div>
											)}
										</div>
									) : (
										''
									)}
									{isFB(venueType) ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('diningStyleLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												style={{ background: '#fff' }}
												value={formikProps.values.dining_style}
												error={formikProps.errors.dining_style}
												placeholder={getTranslation('diningStyleLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												onChange={(e) => {
													formikProps.setFieldValue(
														'dining_style',
														e.target.value
													)
												}}
											/>
											{!isEmpty(formikProps.errors.dining_style) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.dining_style}
												</div>
											)}
										</div>
									) : (
										''
									)}
									{isFB(venueType) ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('dressCodeLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.dress_code}
												error={formikProps.errors.dress_code}
												placeholder={getTranslation('dressCodeLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'dress_code',
														e.target.value
													)
												}}
											/>
											{!isEmpty(formikProps.errors.dress_code) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.dress_code}
												</div>
											)}
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' || venueType === 'vacation_rental' ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('starsLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.stars}
												error={formikProps.errors.stars}
												placeholder={getTranslation('starsLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue('stars', e.target.value)
												}}
											/>
											{!isEmpty(formikProps.errors.stars) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.stars}
												</div>
											)}
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' || venueType === 'vacation_rental' ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('rentalTypeLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.hotel_type}
												error={formikProps.errors.hotel_type}
												placeholder={getTranslation('rentalTypeLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'hotel_type',
														e.target.value
													)
												}}
											/>
											{!isEmpty(formikProps.errors.hotel_type) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.hotel_type}
												</div>
											)}
										</div>
									) : (
										''
									)}
									{venueType === 'hotel'
										|| venueType == 'restaurant' || venueType === 'vacation_rental' ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('hasFreeWifiLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_free_wifi}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'has_free_wifi',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{venueType === 'hotel'
										|| venueType == 'restaurant' || venueType === 'vacation_rental' ? (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('hasFreeBreakfast')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_free_breakfast}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'has_free_breakfast',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{(venueType === 'hotel' || venueType == 'restaurant' ||
										(venueType === 'vacation_rental' &&
											formikProps.values.has_free_wifi)) && (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('wifiNameLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.wifi}
												error={formikProps.errors.wifi}
												placeholder={getTranslation('wifiNameLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue('wifi', e.target.value)
												}}
											/>
											{!isEmpty(formikProps.errors.wifi) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.wifi}
												</div>
											)}
										</div>
									)}
									{venueType === 'hotel' ? (
										<div className="flex flex-col flex-start">
											<RInputDeclare
												value={getTranslation('hasSpaLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_spa}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue('has_spa', e.target.checked)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' ? (
										<div className="flex flex-col flex-start">
											<RInputDeclare
												value={getTranslation('hasEventsHallLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_events_hall}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'has_events_hall',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' ? (
										<div className="flex flex-col flex-start">
											<RInputDeclare
												value={getTranslation('hasGymLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_gym}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue('has_gym', e.target.checked)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' ? (
										<div className="flex flex-col flex-start">
											<RInputDeclare
												value={getTranslation('hasRestaurantLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.has_restaurant}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'has_restaurant',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									) : (
										''
									)}
									{venueType === 'hotel' ? (
										<div className="flex flex-col flex-start">
											<RInputDeclare
												value={getTranslation('roomServiceLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white w-full"
												value={formikProps.values.room_service}
												fullWidth={true}
												onChange={(e) =>
													formikProps.setFieldValue('room_service', e)
												}
											/>
										</div>
									) : (
										''
									)}
									<div className="flex flex-col">
										<RInputDeclare
											value={getTranslation('parkingDetailsLabel')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.parking_details}
											error={formikProps.errors.parking_details}
											placeholder={getTranslation('parkingDetailsLabel')}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											style={{ background: '#fff' }}
											onChange={(e) => {
												formikProps.setFieldValue(
													'parking_details',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.parking_details) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.parking_details}
											</div>
										)}
									</div>
									{(isFB(venueType) || venueType === 'vacation_rental') && (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('paymentOptionsLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomSelect
												values={paymentOptions}
												value={
													formikProps.values.payment_options === null ||
													formikProps.values.payment_options?.length === 0
														? []
														: paymentOptions.filter(
																(item) =>
																	formikProps.values.payment_options?.findIndex(
																		(a) => a === item.name
																	) !== -1
														  )
												}
												isMultiple={true}
												className={' !z-[5] !border-gray6 !h-10'}
												style={{ background: '#fff' }}
												placeholder={getTranslation('paymentOptionsLabel')}
												renderRow={(item) => <p>{item.name}</p>}
												renderValue={(item) => <span>{item?.name}</span>}
												handleChange={(v) => {
													let tmp = formikProps.values.payment_options
														? formikProps.values.payment_options.slice(0)
														: []
													tmp.push(v.name)
													formikProps.setFieldValue('payment_options', tmp)
												}}
												onRemoveItem={(v) => {
													let tmp = formikProps.values.payment_options.slice(0)
													let index = tmp.findIndex((t) => t === v.name)
													if (index !== -1) {
														tmp.splice(index, 1)
													}
													formikProps.setFieldValue('payment_options', tmp)
												}}
											/>
											{!isEmpty(formikProps.errors.payment_options) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.payment_options}
												</div>
											)}
										</div>
									)}
									{isFB(venueType) ? (
										<>
											<div className="flex flex-col">
												<RInputDeclare
													value={getTranslation('allowReservationsLabel')}
													className="!mt-0 !mb-2 !text-black1 font-medium text-base"
												/>
												<Checkbox
													checked={formikProps.values.allow_reservation_from}
													style={{ width: 'fit-content' }}
													onChange={(e) =>
														formikProps.setFieldValue(
															'allow_reservation_from',
															e.target.checked
														)
													}
													className="mt-2"
												/>
											</div>
											<div className="flex flex-col">
												<RInputDeclare
													value={getTranslation('deliveryFeeLabel')}
													className="!mt-0 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													size="small"
													value={formikProps.values.delivery_fee}
													error={formikProps.errors.delivery_fee}
													placeholder={getTranslation('deliveryFeeLabel')}
													sx={{
														'& .MuiOutlinedInput-notchedOutline': {
															border: '1px solid #d9d9d9!important',
														},
													}}
													style={{ background: '#fff' }}
													onChange={(e) => {
														formikProps.setFieldValue(
															'delivery_fee',
															e.target.value
														)
													}}
												/>
												{!isEmpty(formikProps.errors.delivery_fee) && (
													<div className="text-red-600 text-12 mt-2">
														{formikProps.errors.delivery_fee}
													</div>
												)}
											</div>
											<div className="flex flex-col">
												<RInputDeclare
													value={getTranslation('minMoneyLabel')}
													className="!mt-0 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													type={'number'}
													size="small"
													value={formikProps.values.min_money_value}
													error={formikProps.errors.min_money_value}
													placeholder={getTranslation('minMoneyLabel')}
													sx={{
														'& .MuiOutlinedInput-notchedOutline': {
															border: '1px solid #d9d9d9!important',
														},
													}}
													style={{ background: '#fff' }}
													onChange={(e) => {
														formikProps.setFieldValue(
															'min_money_value',
															e.target.value
														)
													}}
												/>
												{!isEmpty(formikProps.errors.min_money_value) && (
													<div className="text-red-600 text-12 mt-2">
														{formikProps.errors.min_money_value}
													</div>
												)}
											</div>
											<div className="flex flex-col">
												<RInputDeclare
													value={getTranslation('maxMoneyLabel')}
													className="!mt-0 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													type={'number'}
													size="small"
													value={formikProps.values.max_money_value}
													error={formikProps.errors.max_money_value}
													placeholder={getTranslation('maxMoneyLabel')}
													sx={{
														'& .MuiOutlinedInput-notchedOutline': {
															border: '1px solid #d9d9d9!important',
														},
													}}
													style={{ background: '#fff' }}
													onChange={(e) => {
														formikProps.setFieldValue(
															'max_money_value',
															e.target.value
														)
													}}
												/>
												{!isEmpty(formikProps.errors.max_money_value) && (
													<div className="text-red-600 text-12 mt-2">
														{formikProps.errors.max_money_value}
													</div>
												)}
											</div>
										</>
									) : (
										''
									)}
									{(isFB(venueType) || venueType === 'vacation_rental') && (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('tagsLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomAutocompleteWithAdd
												values={tags || []}
												selectedValues={formikProps.values.tags || []}
												onRemoveItem={(v) => {
													let tmp = formikProps.values.tags
														? formikProps.values.tags.slice(0)
														: [].slice(0)
													let index = tmp.findIndex((t) => t === v)
													if (index !== -1) {
														tmp.splice(index, 1)
													}
													formikProps.setFieldValue('tags', tmp)
												}}
												handleChange={(v) => {
													let tmp = formikProps.values.tags
														? formikProps.values.tags.slice(0)
														: [].slice(0)
													tmp.push(v)
													formikProps.setFieldValue('tags', tmp)
												}}
											/>
											{!isEmpty(formikProps.errors.tags) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.tags}
												</div>
											)}
										</div>
									)}
									{(isFB(venueType) || venueType === 'vacation_rental') && (
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('benefitsLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												multiline
												size="small"
												value={formikProps.values.benefit_title}
												error={formikProps.errors.benefit_title}
												placeholder={getTranslation('benefitTitleLabel')}
												style={{ background: '#fff' }}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												onChange={(e) => {
													formikProps.setFieldValue('benefit_title', e.target.value)
												}}
											/>
											<CustomAutocompleteWithAdd
												values={benefits || []}
												selectedValues={formikProps.values.benefits || []}
												onRemoveItem={(v) => {
													let tmp = formikProps.values.benefits
														? formikProps.values.benefits.slice(0)
														: [].slice(0)
													let index = tmp.findIndex((t) => t === v)
													if (index !== -1) {
														tmp.splice(index, 1)
													}
													formikProps.setFieldValue('benefits', tmp)
												}}
												handleChange={(v) => {
													let tmp = formikProps.values.benefits
														? formikProps.values.benefits.slice(0)
														: [].slice(0)
													tmp.push(v)
													formikProps.setFieldValue('benefits', tmp)
												}}
											/>
											{!isEmpty(formikProps.errors.benefits) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.benefits}
												</div>
											)}
										</div>
									)}
									<div className="flex flex-col">
										<RInputDeclare
											value={getTranslation('additionalInformationLabel')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											maxRows={5}
											minRows={5}
											multiline
											size="small"
											value={formikProps.values.additional}
											error={formikProps.errors.additional}
											placeholder={getTranslation('additionalInformationLabel')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('additional', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.additional) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.additional}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={getTranslation('descriptionLabel')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											maxRows={5}
											minRows={5}
											multiline
											value={formikProps.values.description}
											error={formikProps.errors.description}
											placeholder={getTranslation('descriptionLabel')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('description', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.description) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.description}
											</div>
										)}
									</div>
								</div>
								<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
									<button className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1">
										{getTranslation('clearButton')}
									</button>
									<button
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={() => {
											formikProps.handleSubmit()
										}}
									>
										{getTranslation('updateButton')}
									</button>
								</div>
							</div>
						</CardContent>
					</Card>
				</>
			) : (
				<div className="border border-b-0 mb-10">
					<HotelEmptyData
						content={getTranslation('venue_web_profile')}
						onAction={() => setConfigured(true)}
					/>
				</div>
			)}
		</div>
	)
}

export default VenueWebProfileScreen
