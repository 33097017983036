import Cookies from "universal-cookie";
import { Config } from "../constants";
import { createUUID } from "../utils/common";

const cookies = new Cookies();

export const KEYS = {
  CHAT_HISTORY: 'CHAT_HISTORY',
  CHAT_LAST_MSG: 'CHAT_LAST_MSG',
  LANGUAGE: "venue-boost-language",
  DEVICE_UNIQUE_KEY: "venue-boost-device-unique-id",
  APP_TOKEN: "venue-boost-app-token",
  VENUE_TYPE: "venue-type",
  VENUE_SHORT_CODE: "vb-venue-short-code",
  VENUE_BASIC_INFO: "vb-basic-info",
  LOYALTY_PROGRAM_ID: "loyalty-program-id",
  FOOD_CART: 'FOOT_CART',
  RETAIL_CART: 'RETAIL_CART',
  IS_TRIAL_MODE: 'IS_TRIAL_MODE',
  SHOW_UPGRADE_FROM_TRIAL: 'SHOW_UPGRADE_FROM_TRIAL',
  PLAN_FEATURES: 'PLAN_FEATURES',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
  is_vision_track: 'is_vision_track',
  is_demo: 'is_demo',

};



export const isValidToken = (token) => {
  let currentToken = getAppToken();
  return token === currentToken;
}

export const setAppToken = (token) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.APP_TOKEN, token, { path: "/", expires: d });
};

export const setVenueShortcode = (venueShortCode) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.VENUE_SHORT_CODE, venueShortCode, { path: "/", expires: d });
};

export const setVenueBasicInfo = (venueBasicInfo) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.VENUE_BASIC_INFO, venueBasicInfo, { path: "/", expires: d });
};

export const setCookieVenueType = (venueType) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.VENUE_TYPE, venueType, { path: "/", expires: d });
};

export const clearAppToken = () => {
  let d = new Date("1970-01-01");
  cookies.remove(KEYS.APP_TOKEN, { path: "/", expires: d });
};

export const getAppToken = () => {
  // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0LCJ0b2tlbiI6IjNMNjJYT1dLcTBlM1ZjYjNvVVdDYU5JNmVJRFZpdmNhNDUwbll1MnZMRjhJaU9oNlhieHVnRUowVWJ4Vzc0RENXV2h0cUkxMUg0S3VNdHhIQmFCY1ZXVWlEU0t6NmUxM2x0ZXkifQ.Gy_qtsz4C4eW20fxG1dOGYMwCluB_6bNDULlksHVkIk';
  return cookies.get(KEYS.APP_TOKEN);
};

export const getDeviceUniqueId = () => {
  let uniqueId = localStorage.getItem(KEYS.DEVICE_UNIQUE_KEY);
  if (uniqueId == null) {
    uniqueId = createUUID();
    localStorage.setItem(KEYS.DEVICE_UNIQUE_KEY, uniqueId);
  }
  return uniqueId;
};

export const getLanguage = () => {
  let savedLang = localStorage.getItem(KEYS.LANGUAGE);
  if (savedLang == null || (savedLang !== "en" && savedLang !== "sq")) {
    savedLang = Config.DEFAULT_LANG;
    localStorage.setItem(KEYS.LANGUAGE, savedLang);
  }
  return savedLang;
};

export const setLanguage = (lang) => {
  localStorage.setItem(KEYS.LANGUAGE, lang);
};

export const getStorageKey = (key) => {
  let data = localStorage.getItem(key);
  if (data != null) {
    return JSON.parse(data);
  }
  return null;
};

export const setStorageKey = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeStorageKey = (key) => {
  localStorage.removeItem(key);
};

export const setLoyaltyProgramId = (programId) => {
  cookies.set(KEYS.LOYALTY_PROGRAM_ID, programId);
};

export const getLoyaltyProgramId = () => {
  cookies.get(KEYS.LOYALTY_PROGRAM_ID);
};

export const updateCart = (product) => {
  const existingCart = cookies.get(KEYS.FOOD_CART) || [];






  let findProduct = existingCart?.map(prod => prod?.id)?.indexOf(product?.id)

  if (findProduct >= 0) {
    existingCart[findProduct].quantity += 1;
  } else {
    existingCart?.push(
      {
        id: product?.id,
        title: product?.title,
        price: product?.price,
        currency: product?.currency,
        quantity: 1
      }
    )
  }

  cookies.set(KEYS.FOOD_CART, existingCart, { path: '/' });
};



export const getFoodCart = () => {
  let foodCart = cookies.get(KEYS.FOOD_CART);

  return foodCart
};

export const deletFromCart = (id) => {
  const existingCart = cookies.get(KEYS.FOOD_CART) || [];






  let findProduct = existingCart?.map(prod => prod?.id)?.indexOf(id)

  if (findProduct >= 0) {
    existingCart?.splice(findProduct, 1)
  }
  cookies.set(KEYS.FOOD_CART, existingCart, { path: '/' });
};

export const emptyFoodCart = () => {
	cookies.set(KEYS.FOOD_CART, [], { path: '/' })
}


export const updateRetailCart = (product) => {
  const existingCart = cookies.get(KEYS.RETAIL_CART) || [];





  let findProduct = existingCart?.map(prod => prod?.id)?.indexOf(product?.id)

  if (findProduct >= 0) {
    existingCart[findProduct].quantity += 1;
  } else {
    existingCart?.push(
      {
        id: product?.id,
        title: product?.title,
        price: product?.price,
        currency: product?.currency,
        quantity: 1
      }
    )
  }

  cookies.set(KEYS.RETAIL_CART, existingCart, { path: '/' });
};



export const getRetailCart = () => {
  let foodCart = cookies.get(KEYS.RETAIL_CART);

  return foodCart
};

export const deletFromRetailCart = (id) => {
  const existingCart = cookies.get(KEYS.RETAIL_CART) || [];






  let findProduct = existingCart?.map(prod => prod?.id)?.indexOf(id)

  if (findProduct >= 0) {
    existingCart?.splice(findProduct, 1)
  }
  cookies.set(KEYS.RETAIL_CART, existingCart, { path: '/' });
};

export const emptyRetailCart = () => {
	cookies.set(KEYS.RETAIL_CART, [], { path: '/' })
}

// eslint-disable-next-line
export default {
	KEYS,
	clearAppToken,
	setAppToken,
	getAppToken,
	getLanguage,
	getStorageKey,
	setStorageKey,
	removeStorageKey,
	getDeviceUniqueId,
	setLoyaltyProgramId,
	updateCart,
	getFoodCart,
	deletFromCart,
	emptyFoodCart,
	emptyRetailCart,
}
