import { parseLabel } from "./AssignActivityModal";

export const columns = [

   {
      label: 'Name',
      field: 'name',
      align: 'left'
   },
   {
      label: 'Code',
      field: 'code',
      align: 'left'
   },
   {
      label: 'Description',
      field: 'description',
      align: 'left'
   },
   {
      label: 'Category',
      field: 'category',
      align: 'left'
   },
   {
      label: 'Default Config',
      field: 'default_config',
      align: 'left',
      cellComponent: ({ data }) => {
         return <div className="flex flex-col">
            <span>Count In: {data.count_in ? 'Yes' : 'No'}</span>
            <span>Count Out: {data.count_out ? 'Yes' : 'No'}</span>
         </div>;
      }
   },
   {
      label: 'Status',
      field: 'is_active',
      align: 'left',
      cellComponent: ({ data }) => {
         return <div className="w-full flex align-end"> <span className={`px-[12px] py-[2px] text-[12px] rounded ${data ? "text-[#088B3A] bg-[#E7F7ED]" : "text-[#DC2828] bg-[#FFD4CD]"}`}>{data ? 'Active' : 'Inactive'}</span></div>;
      }
   }
];

export const venueColumns = [
   {
      label: 'Activity',
      field: 'activity',
      align: 'center',
      cellComponent: ({ data }) => {
         return <span>{data.name}</span>;
      }
   },
   {
      label: 'Config',
      field: 'config',
      align: 'config',
      cellComponent: ({ data }) => {
         return <div className="flex flex-col">{data && Object.entries(data).map(
            ([key, value]) => (<span>{parseLabel(key)}: {value}</span>))}
         </div>;
      }
   },
   {
      label: 'Status',
      field: 'is_enabled',
      align: 'right',
      cellComponent: ({ data }) => {
         return <div className="w-full flex align-end"><span className={`px-[12px] py-[2px] text-[12px] rounded ${data ? "text-[#088B3A] bg-[#E7F7ED]" : "text-[#DC2828] bg-[#FFD4CD]"}`}>{data ? 'Active' : 'Inactive'}</span></div>;
      }
   },
]


export const deviceActivitiesColumns = [
   {
      label: 'Device-id',
      field: 'device',
      align: 'center',
      cellComponent: ({ data }) => {
         return <span>{data.device_id}</span>
      }
   },
   {
      label: 'Device Nickname', field: 'device', align: 'left',
      cellComponent: ({ data }) => {
         return <span>{data?.device_nickname}</span>
      }
   },
   {
      label: 'Venue Activity',
      field: 'venue_activity',
      align: 'left',
      cellComponent: ({ data }) => {
         return <span>{data.activity.name}</span>
      }
   },
   {
      label: 'Config',
      field: 'config',
      align: 'left',
      cellComponent: ({ data }) => {
         return <div className="flex flex-col">{Object.entries(data).map(
            ([key, value]) => (<span>{parseLabel(key)}: {value}</span>))}
         </div>;
      }
   },
   {
      label: 'Status',
      field: 'is_active',
      align: 'left',
      cellComponent: ({ data }) => {
         return <span className={`px-[12px] py-[2px] text-[12px] rounded ${data ? "text-[#088B3A] bg-[#E7F7ED]" : "text-[#DC2828] bg-[#FFD4CD]"}`}>{data ? 'Active' : 'Inactive'}</span>;
      }
   },
   {
      label: "Actions",
      field: "actions",
      align: 'left',
   }
]