import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import logoutWhiteIcon from '../../assets/svgs/v2-logout.svg'
import { PromotionHeaderSection } from '../../containers/Navbar'
import useIsMobile from '../../helpers/is-mobile-hook'
import LoginService from '../../services/loginService'
import {
	SET_IS_TRIAL_MODE,
	SET_PLAN_FEATURES,
	SET_SHOW_UPGRADE_FROM_TRIAL,
	SET_SUBSCRIPTION_PLAN,
	SET_USER_PROFILE,
} from '../../store/actions/types'
import { setVisibleMenu } from '../../store/modules/menuStore'
import {
	KEYS,
	clearAppToken,
	removeStorageKey,
	setAppToken,
	setCookieVenueType,
	setLoyaltyProgramId,
	setVenueBasicInfo,
	setVenueShortcode,
} from '../../utils/storage'
import { useFeatures } from './data'
import { featureIcons } from './icons'
import './index.css'
import VisionTrackSubMenus from './sub-menus'

const VisionTrack = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { short_code } = useSelector((state) => state.app)
	const isDemo = localStorage.getItem(KEYS.is_demo)
	const features = useFeatures()

	const isMobile = useIsMobile()
	const openMenu = useSelector((state) => state.menu.visible)

	const [selectedMenu, setSelectedMenu] = useState('Devices')
	const [expandedMenu, setExpandedMenu] = useState(null)

	useEffect(() => {
		if (isMobile) {
			dispatch(setVisibleMenu(false))
		}
	}, [isMobile])

	const onSidebarChange = (path) => {
		dispatch(setVisibleMenu(false))

		navigate(`/${short_code}/admin/${path}`)
	}

	const toggleSubmenu = (menu) => {
		setExpandedMenu(expandedMenu === menu ? null : menu)
		setSelectedMenu(menu)
	}

	const getFeaturePath = (key, submenu) => {
		if (Array.isArray(submenu) && submenu.length === 0) {
			return `vision-track/${key.toLowerCase()}`
		}
		return null
	}

	const handleSidebarChange = (key) => {
		const path = getFeaturePath(key, features[key])
		if (path) {
			setSelectedMenu(key)
			onSidebarChange(path)
		} else {
			toggleSubmenu(key)
		}
	}

	const handleSubmenuClick = (submenuPath) => {
		onSidebarChange(submenuPath)
	}

	const getDisplayText = (key) => {
		if (key === 'Staff Management') return 'Staff'
		if (key === 'Vehicle Management') return 'Vehicle'
		return key
	}

	const toggleDrawer = () => {
		dispatch(setVisibleMenu(!openMenu))
	}

	const demoTabs = [
		'Dashboard',
		'Main',
		'Monitoring Summary',
		'Analytics',
		'Devices',
		'Environment',
		'Activity Recognition',
		'Monitor',
		'Settings',
		'General',
	]

	return (
		<div className="flex w-full h-full relative">
			<CustomDrawer
				isMobile={isMobile}
				toggleDrawer={toggleDrawer}
				openMenu={openMenu}
			>
				<div
					className="sm:fixed sm:top-[80px] flex flex-col justify-start items-center sm:w-[200px] sm:py-5 vision-track-sidebar sm:px-2"
					style={{
						height: isMobile ? 'unset' : 'calc(100vh - 80px)',
						border: isMobile ? 'unset' : '0 1px 0 0 ',
					}}
				>
					<div className="flex items-center mb-5">
						<h3 className="font-manrope font-extrabold text-20 text-black1 leading-5">
							VisionTrack
						</h3>
					</div>
					<div className="flex overflow-y-auto flex-1 flex-col w-full">
						{features &&
							Object.keys(features).map((key, index) => {
								if (!demoTabs.includes(key)) {
									return
								}
								const isActive = Boolean(selectedMenu === key)
								const isExpanded = Boolean(expandedMenu === key)

								return (
									<div
										key={index}
										className={`w-full rounded-[8px] ${
											isActive ? 'bg-[#f7eeff]' : ''
										}`}
									>
										<div
											className={`${isActive ? 'active' : 'inactive'} ${
												isExpanded && isActive
													? '!pt-[12px] !pb-[0px]'
													: '!py-[12px]'
											}  !px-[10px]  cursor-pointer w-full flex justify-between items-center`}
											onClick={() => handleSidebarChange(key)}
										>
											<span className="flex items-center">
												{featureIcons[key] && (
													<span className="mr-2">{featureIcons[key]}</span>
												)}
												<div className={`${isActive ? 'font-bold' : ''}`}>
													{getDisplayText(key)}
												</div>
											</span>
											{Boolean(Object.keys(features[key]).length) && (
												<>
													{isExpanded && isActive ? (
														<ExpandLessIcon />
													) : (
														<ExpandMoreIcon />
													)}
												</>
											)}
										</div>

										{features?.[key] &&
											expandedMenu === key &&
											isActive &&
											Object.keys(features?.[key] ?? {}).length > 0 && (
												<VisionTrackSubMenus
													{...{
														features,
														handleSubmenuClick,
														subMenuKey: key,
														demoTabs,
													}}
												/>
											)}
									</div>
								)
							})}
					</div>
				</div>
			</CustomDrawer>
			<div className="flex-1 px-6 py-3 sm:ml-[200px] sm:mr-2 w-full">
				<Outlet />
			</div>
		</div>
	)
}

const CustomDrawer = ({ isMobile, toggleDrawer, openMenu, children }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleCloseUserMenu = () => {
		LoginService.logout()
			.then(() => {
				dispatch({ type: SET_USER_PROFILE, payload: null })
				setAppToken(null)
				setVenueShortcode(null)
				setVenueBasicInfo(null)
				setCookieVenueType(null)
				setLoyaltyProgramId(null)

				// Dispatch and save to storage the new status to update the application's state
				dispatch({
					type: SET_IS_TRIAL_MODE,
					payload: false,
				})
				removeStorageKey(KEYS.IS_TRIAL_MODE)
				removeStorageKey(KEYS.is_vision_track)

				dispatch({
					type: SET_SHOW_UPGRADE_FROM_TRIAL,
					payload: false,
				})
				removeStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL)
				dispatch({
					type: SET_PLAN_FEATURES,
					payload: [],
				})
				removeStorageKey(KEYS.PLAN_FEATURES)

				dispatch({
					type: SET_SUBSCRIPTION_PLAN,
					payload: {},
				})
				removeStorageKey(KEYS.SUBSCRIPTION_PLAN)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
				throw new Error('Problem on logout')
			})
			.finally(() => {
				navigate('../')
				clearAppToken()
			})
	}

	if (isMobile) {
		return (
			<Drawer open={openMenu} onClose={toggleDrawer} placement="left">
				<div className="w-full h-full flex flex-col justify-between">
					<div>
						<PromotionHeaderSection />
						{children}
					</div>
					<div
						className="cursor-pointer w-full active"
						onClick={handleCloseUserMenu}
					>
						<span className={`text-black text-[15px] flex gap-3`}>
							<img
								src={logoutWhiteIcon}
								alt="logout"
								className="min-w-[20px]"
							/>
							Logout
						</span>
					</div>
				</div>
			</Drawer>
		)
	}
	return children
}

export default VisionTrack
